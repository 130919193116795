@mixin mobile {
    @media (max-width: #{$mobile}) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: #{$tablet}) {
        @content;
    }
}

@mixin small-desktop {
    @media (max-width: #{$small-desktop}) {
        @content;
    }
}

@mixin desktop {
    @media (max-width: #{$desktop}) {
        @content;
    }
}