@import '../../../scss/global.scss';

.privacy {
    padding-top: calc($content-padding-top-desktop + 16px);

    @include small-desktop {
        padding-top: calc($content-padding-top + 16px);
    }

    &__list {
        li {
            list-style: decimal;
        }
    }
}
