@import '../../../scss/global.scss';

.cookies {
    position: fixed;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;

    &__wrapper {
        width: 100%;
        padding: 20px 16px;
        background: rgba(19, 19, 19, 0.8);
        backdrop-filter: blur(6px);
        border-radius: 16px;
        display: flex;
        gap: 16px;
        align-items: center;

        @include tablet {
            flex-direction: column
        }
    }


    &__text {
        @include t_14;
        color: $text-secondary;

        span {
            color: $text-actions;
        }
    }

    &__btn {
        border: none;
        padding: 12px 24px;
        height: auto;
        width: auto;

        span {
            @include t_14;
            font-weight: 700 !important;
        }

        @include tablet {
            width: 100%;
            padding-top: 12px;
            padding-bottom: 12px;
        }
    }
}
