@import 'scss/global.scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.step {
  display: flex;
  align-items: center;
  flex-grow: 1;
  gap: 10px;

  @include tablet{
    gap: 5px;
  }

  &:last-of-type {
    width: auto;
    flex-grow: 0;
  }
}

.stepTitle{
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  @include tablet{
    gap: 5px;
  }
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $icon-secondary;
  color: $text-secondary;
  transition: background 0.3s ease;

  @include tablet{
    width: 15px;
    height: 15px;
  }
}

.progressLine {
  height: 2px;
  background-color: #D8D8D8;
  position: relative;
  margin: 0 20px;
  flex-grow: 1;

  @include tablet{
    margin: 0 10px;
  }
}

.progressFill {
  height: 100%;
  background-color: $bg;
  transition: width 0.3s ease;
}

.completed {
  background: $bg;
}

.title{
  @media (max-width: 600px){
    display: none;
  }
}