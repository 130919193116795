@import "../../../scss/global.scss";

.customer {
    background: $bg-primary;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 32px;

    @include tablet {
        padding: 16px;
    }

    &__title {
        @include t_24;
        color: $text-primary;
    }

    &__subtitle {
        @include t_16;
        color: $text-primary;
        margin-top: 4px;
    }

    &__inputs {
        margin-top: 16px;
        display: grid;
        grid-template-columns: minmax(calc(50% - 16px), 1fr) minmax(130px, 1fr) 3fr;
        gap: 16px;

        @include tablet {
            grid-template-columns: 160px 2fr auto;
            gap: 16px;

            div {
                &:nth-child(1) {
                    grid-column: 1 / -1;
                }

                &:nth-child(2) {
                    width: 100%;
                    grid-row: 2;
                }

                &:nth-child(3) {
                    grid-column: 2 / -1;
                }
            }
        }
    }
}
