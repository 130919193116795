@import 'scss/global.scss';

.form {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: 100%;
    background: $bg-primary;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 32px;
    gap: 26px;

    @include tablet {
        padding: 16px;
    }
}

.formInputs{
    display: flex;
    flex-grow: 1;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;

    @include tablet {
    }

    &>*{
        width: calc(50% - 8px);

        @include tablet {
            width: 100%;
        }
    }
}

.note{
    color: $text-secondary2;
}
