@import '../../scss/global.scss';

.notFound {

    padding-top: calc($content-padding-top-desktop + 16px);

    @include small-desktop {
        padding-top: calc($content-padding-top + 16px);
    }

    margin-top: 44px;

    @include tablet {
        margin-top: 36px;
    }

    &__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    &__title {
        @include t_48;
        color: $text-primary;
        margin-top: 20px;
        text-align: center;
    }

    &__subtitle {
        @include t_18;
        color: $text-primary;
        margin-top: 8px;
        text-align: center;
    }

    &__btn {
        border: none;
        margin-top: 36px;
        color: $text-secondary;
        @include t_16;
        font-weight: 700 !important;
    }
}