@import '../../scss/global.scss';

.main {

    &__hidden{
        height: 0;
        overflow: hidden;
    }

    &__top {
        background-image: url('../../assets/img/bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        padding-top: $content-padding-top-desktop;
        border-radius: 0px 0px 24px 24px;

        @include small-desktop {

            padding-top: $content-padding-top;
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 41px 0 62px;

        @include tablet {
            padding: 31px 0 62px;

        }

    }

    &__title {
        @include t_72;
        text-align: center;
        color: $text-secondary;
    }

    &__subtitle {
        @include t_18;
        margin-top: 16px;
        text-align: center;
        color: $text-secondary;
    }

    &__tabs {
        margin-top: 32px;
        width: 100%;
    }

    &__rating {
        margin-top: 16px;
        padding: 8px;
        background: $bg-primary;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
        border-radius: 64px;
        display: flex;
        gap: 8px;

        &_text {
            color: $text-secondary;
        }

        &_rate {
            color: $text-primary;
            @include t_24;
            font-weight: 700 !important;
        }

        &_rightSide {
            background: $bg-primary2;
            border-radius: 24px;
            padding: 8px 12px;
            text-align: center;
            display: flex;
            gap: 2px;
            align-items: center;
        }

        &_leftSide {
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }
}
