@import '../../scss/global.scss';

.footer {
    padding-top: 70px;
    background: $bg-secondary;
    padding-bottom: 24px;

    @include small-desktop {
        padding-top: 40px;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    &__divider {
        width: 100%;
        height: 1px;
        background-color: $btn-addetional-hover;
    }

    &__logo {
        text-transform: uppercase;
        display: flex;
        align-items: center;
        width: min-content;

        &_title {
            p {
                color: $text-secondary2;
                @include t_14;
            }
        }

        &_img {
            width: 36px;
            height: 30px;
            margin-right: 8.5px;
            background: url(../../assets/icons/logo.svg);
            background-repeat: no-repeat;
            background-size: contain;
        }

        &_text {
            background: url(../../assets/icons/AirSurfer.svg);
            background-position: center;
            background-repeat: no-repeat;
            width: 108.78px;
            height: 18.13px;
        }


    }

    &__about {
        &_text {
            a {
                color: $text-primary;
                @include t_14;

                &:hover {
                    color: $text-actions;
                }
            }
        }

        &_title {
            a {
                font-weight: 700 !important;
                color: $text-primary;
                @include t_14;

                &:hover {
                    color: $text-actions;
                }
            }
        }
    }

    &__top {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 16px;

        @include small-desktop {
            grid-template-columns: repeat(2, 1fr);

            .footer__top_list {
                &:not(:nth-child(3)) {
                    grid-column: 1;
                }

            }
        }

        @include mobile {
            grid-template-columns: 1fr;

            .footer__top_list {
                &:nth-child(3) {
                    grid-row: 3;
                }
            }
        }

        &_list {
            display: flex;
            flex-direction: column;
            gap: 12px;
        }

    }

    &__support {
        &_title {
            a {
                font-weight: 700 !important;
                color: $text-primary;
                @include t_14;
                &:hover {
                    color: $text-actions;
                }
            }
        }

        &_email {
            a {
                text-decoration: none;
                color: $text-primary;
                @include t_20;

                &:hover {
                    color: $text-actions;
                }
            }
        }
    }
    &__payment_icons{
        max-width: 300px;
    }
    &__bottom {
        &_list {
            display: flex;
            justify-content: space-between;
            gap: 16px;

            @include small-desktop {
                flex-wrap: wrap;
                justify-content: flex-start;
            }

            @include tablet {
                flex-direction: column;
            }
        }

        &_item {
            color: $text-secondary2;
            @include t_14;
            text-decoration: none;

            &:last-child {
                margin-right: 0;

                @include small-desktop {
                    flex-basis: 100%;
                }
            }

            a {
                color: $text-secondary2;
                @include t_14;

                &:hover {
                    color: $text-actions;
                }

            }
        }
    }
}

.button {
    background-color: transparent;
    display: flex;
    align-items: center;
    font-weight: 700 !important;
    @include t_14;

    &:hover {
        border-bottom: 2px solid #e2e2e2;
    }

    &__img_heart {
        width: 15.5px;
        height: 12.8px;
        margin-right: 4.8px;
        background: url(../../assets/icons/heart-nav.svg);
    }

    &__img_doc {
        width: 14px;
        height: 17px;
        margin-right: 5.8px;
        background: url(../../assets/icons/doc-nav.svg);
    }

    &__disabled {
        background: transparent;
        display: flex;
        /*align-items: center;*/
        justify-content: flex-start;
        font-weight: 700 !important;
        text-align: left;
        @include t_14;
        color: $text-primary;

        &:hover {
            cursor: not-allowed;
        }

        &_info {
            margin-left: 4px;
            width: 47px;
            height: 20px;
            background: #0094ff;
            border-radius: 24px;
            text-align: center !important;

            &::after {
                content: "Скоро";
                font-size: 10px;
                color: #ffffff;
            }
        }
    }

}
