@import 'scss/global.scss';

.trail {
    background-color: $bg-secondary;
    padding: 64px 0;

    @include tablet {
        padding: 0 0 40px 0
    }

    &__title {
        @include t_48;

        span {
            color: $text-actions;
        }
    }

    &__items {
        margin-top: 48px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        gap: 16px;

        @include small-desktop {
            margin-top: 24px;
        }

        @include mobile {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }
    }

    &__item {
        background: $bg-additional;
        border-radius: 24px;
        padding: 16px 16px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;

        @include tablet {
            padding: 8px 8px 0;
        }

        @include tablet {
            padding: 8px 8px 0;
        }

        &:nth-child(3) {

            @include small-desktop {
                grid-column: 1/3;
            }
        }

        &_img {
            width: 100%;
            @include small-desktop {
                max-width: 370px;
                transform: translateX(-50%);
                margin-left: 50%;
            }
        }

        &_count {
            background: $bg-primary;
            border-radius: 24px;
            width: 35px;
            height: 35px;
            color: $text-primary;
            display: flex;
            align-items: center;
            justify-content: center;
            @include t_16;
            font-weight: 700;
        }

        &_title {
            color: $text-secondary;
            @include t_32;
            margin-top: 8px;
        }

        &_text {
            margin-top: 8px;
            color: $text-secondary2;
            @include t_16;

            span {
                color: $text-actions;
                cursor: pointer;
            }
        }

        &_top {
            padding: 24px;
            border-radius: 24px;
            display: flex;
            flex-direction: column;

            @include tablet {
                padding: 16px;
            }
        }
    }



}

.content{
    display: flex;
    gap: 8px;
    align-items: baseline;
}