@import "reset";
@import './global.scss';

* {
    font-family: 'Golos Text', sans-serif;
    line-height: normal;
}

body {
    background-color: $bg-secondary;

}

.main {
    // padding-top: $content-padding-top-desktop;

    // @include small-desktop {
    //     margin-top: $content-margin-top;
    // }
}

.container1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: calc($content-padding-top-desktop + 16px);
    //     padding-top: $content-padding-top;
    // }
    // margin-top: 123px;
    margin-top: 123px;

    @include small-desktop {
        padding-top: calc($content-padding-top + 16px);
    }
}


.container {
    max-width: 1440px;
    padding: 0 44px;
    margin: 0 auto;

    @include small-desktop {
        padding: 0 24px;
    }
    @include tablet{
        padding: 0 16px;
    }
}

.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main {
    flex: 1 0 auto;
}

.footer {
    margin-top: auto;
    height: 100px;
}


.player-wrapper {
    width: auto; // Reset width
    height: auto; // Reset height
  }
  .react-player {
    padding-top: 56.25%; // Percentage ratio for 16:9
    position: relative; // Set to relative
  }

  .react-player > div {
    position: absolute; // Scaling will occur since parent is relative now
  }
