@import '../../../scss/global.scss';

.ticket {
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1));

    &__main {
        &_btn {
            background: 0;
            margin-right: auto;
            color: $text-actions;
            @include t_14;
            display: flex;
            gap: 9px;
            align-items: center;
        }

        &_rightBtn {
            background-image: url('../../../assets/icons/arrow-bottom.svg');
            background-repeat: no-repeat;
            width: 15px;
            height: 9px;
        }
    }
}
