@import "../../../scss/variables.scss";

.custom_rating{
  :global{
    .ant-rate-star-full{
      color: $btn-primary;
    }
    .ant-rate-star-zero{

      .ant-rate-star-second{
        color: $bg-secondary2;
      }
    }
  }
}