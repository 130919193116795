@import '../../../../scss/global.scss';

.burger {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 24px;
    height: 24px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
    right: 0;

    &:focus {
        outline: none;
    }

    div {
        width: 24px;
        height: 1.7px;
        border-radius: 10px;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 1px;
        background: $bg;
    }
}