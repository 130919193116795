@import '../../../scss/global.scss';

.wrapper {
    position: relative;
    display: inline-block;

    &__active {
        div {
            svg {
                path {
                    fill: #ed7e2e;
                }
            }
        }
    }
}

.target {
    width: 100%;
}

.tooltip {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -20px;
    left: 50%;
    width: max-content;
    padding: 8px;
    border-radius: 4px;
    transform: translateX(-50%);
    z-index: 1;
    background: $bg-additional;
    border-radius: 8px;
    padding: 8px 12px;
    color: $text-secondary;
    @include t_12;
    margin-left: 0!important;
    margin-right: 0!important;
    @include tablet {
        top: -28px;
    }
    &::after {
        content: '';
        position: absolute;
        top: 100%;
        border-width: 6px;
        transform: rotate(180DEG);
        border-style: solid;
        border-color: transparent transparent $bg-additional transparent;
    }
}