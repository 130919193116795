@mixin t_72 {
    font-weight: 700;
    font-size: 72px;
    line-height: 86px;

    @media (max-width: 768px) {
        font-weight: 700;
        font-size: 34px;
        line-height: 41px;
    }

}

@mixin t_48 {
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;

    @media (max-width: 768px) {
        font-weight: 700;
        font-size: 32px;
        line-height: 38px;
    }
}

@mixin t_32 {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;

    @media (max-width: 768px) {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
    }
}

@mixin t_24 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;

    @media (max-width: 768px) {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
    }
}

@mixin t_20 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;

    @media (max-width: 768px) {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
    }
}

@mixin t_18 {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;

    @media (max-width: 768px) {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
    }
}

@mixin t_16 {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    @media (max-width: 768px) {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
    }
}

@mixin t_14 {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    @media (max-width: 768px) {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
    }
}

@mixin t_12 {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    @media (max-width: 768px) {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
    }
}