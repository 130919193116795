@import 'scss/global.scss';

.status_text {
    background-color: #0094FF;
    padding: 4px 8px;
    font-size: 10px;
    text-align: center;
    font-weight: 700;
    border-radius: 24px;
    color: #fff;
    top: 16px;
}

.link{
    @include t_14;
    color: $bg;
    margin-top: 4px;
    cursor: pointer;
}

.form{
    display: flex;
    flex-direction: row;
    @include small-desktop{
        flex-direction: column;
    }
}

.user{
    display: flex;
    gap: 16px;
    align-items: center;
}