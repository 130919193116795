@import './mixins.scss';

$text-primary: #000000;
$text-secondary: #ffffff;
$text-secondary2: #8a8a8a;
$text-secondary3: #cfcfcf;
$text-actions: #ed7e2e;
$text-actions-hover: #c96b27;
$text-error: #ff6240;
$text-done: #3cc339;
$text-black: #222222;
$text-done: #4db65d;

$bg-primary: #ffffff;
$bg-secondary: #f7f8fa;
$bg-secondary2: #cfcfcf;
$bg-primary2: #131313;
$bg-additional: #282828;
$bg-additional2: #353535;
$bg-additional3: #4A4A4A;
$bg-additional4: #f5f5f5;
$bg: #ed7e2e;
$bg-primary3: #c96b27;

$btn-primary: #ed7e2e;
$btn-primary-hover: #c96b27;
$btn-secondary: #131313;
$btn-secondary2: #0094FF;
$btn-secondary-hover: #363636;
$btn-addetional: #f5f5f5;
$btn-addetional-hover: #e1e1e1;

$border-grey: #ebebeb;

$icon-secondary: #ABABB8;

$content-margin-top: 100px;
$content-margin-top-desktop: 135px;

$content-padding-top: 61px;
$content-padding-top-desktop: 122px;