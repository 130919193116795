@import '../../../scss/global.scss';

.partner {
    background: $bg-secondary;


    &__wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(504px, 1fr));
        gap: 18px;

        @include tablet {
            display: flex;
            flex-direction: column;
        }
    }

    &__icon {
        cursor: pointer;
        display: inline;
        position: relative;

        &::after {
            content: "Скоро";
            position: absolute;
            color: $text-secondary;
            background: $btn-secondary2;
            border-radius: 24px;
            padding: 4px 8px;
            top: -8px;
            right: -16px;
            @include t_12
        }
    }

    &__card {
        background: $bg;
        border-radius: 24px;
        padding: 32px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include tablet {
            padding: 16px;
        }


        @include mobile {
            padding-bottom: 170px;
        }

        &:nth-child(2) {
            @include mobile {
                padding-bottom: 240px;

            }
        }

        &_icons {
            display: flex;
            flex-direction: column;
            margin-top: 160px;
            gap: 16px;
            align-items: flex-start;

            @include tablet {
                margin-top: 60px;

            }

            @include mobile {
                margin-top: 32px;
            }
        }

        &_btn {
            border-radius: 12px;
            @include t_16;
            color: $text-secondary;
            padding: 20px 24px;
            background: $btn-secondary;
            margin-top: auto;

            @include small-desktop {
                margin-top: 140px;
            }

            @include tablet {
                margin-top: 16px;
                width: 75%;
            }

            @include mobile {
                width: 100%;
            }
        }

        &:nth-child(2) {
            background: $bg-primary2;
        }

        &_title {
            @include t_32;
            color: $text-secondary;
            width: 45%;

            @include tablet {
                margin-top: 16px;
                width: 100%;
            }
        }

        &_subtitle {
            @include t_16;
            color: $text-secondary;
            margin-top: 8px;
            width: 45%;

            @include tablet {
                margin-top: 16px;
                width: 100%;
            }

            @include mobile {
                margin-top: 8px;
                ;
            }
        }

        &_firstImg {
            position: absolute;
            bottom: 0;
            right: 0;
            max-width: 330px;

            @include tablet {
                width: 20%;
            }

            @include mobile {
                width: 164px;

            }
        }

        &_secondImg {
            position: absolute;
            bottom: 0;
            right: 0;
            height: 100%;

            @include tablet {
                height: 60%;
            }

            @include tablet {
                padding: 18px 20px 0;
            }

            @include mobile {
                height: 210px;
                width: auto;
                padding: 0;
            }


        }
    }




}