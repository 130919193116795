@import '../../../scss/global.scss';

.registered {
    background: #FFE7D3;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 32px;
    position: relative;
    z-index: 10;

    @include tablet {
        padding: 16px;
    }


    &__title {
        @include t_24;
        font-weight: 700 !important;
        color: $text-primary;
    }

    &__subtitle {
        margin-top: 4px;
        color: $text-primary;
        @include t_16;
    }

    &__btn {
        height: auto;
        padding: 12px 24px;
        background: $bg;
        border: 0;
        border-radius: 12px;
        margin-top: 18px;
        @include t_14;
        font-weight: 700 !important;
        color: $text-secondary;

        @include tablet {
            width: 100%;
            z-index: 10;
            margin-bottom: 80px;
        }
    }

    &__img {
        position: absolute;
        right: 32px;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;
        max-width: 10%;

        @include tablet {
            max-width: 64px;
            bottom: 16px;
            right: 16px;
            top: auto;
            transform: none;
        }
    }
}
