@import "../../../scss/global.scss";

.custom_divider{
  :global{
    .ant-divider-inner-text{
      color: $text-secondary2;
    }
  }

  &:after, &:before{
    border-block-start: 1px solid $btn-addetional-hover !important;
    border-block-end: none;
    transform: none !important;
  }
}