@import "../../../scss/global.scss";

.payment {
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1));
    border-radius: 16px;
    background: $bg-primary;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: 80vh;
    pointer-events: all;

    @include small-desktop {
        overflow-y: initial;
        max-height: 100%;
    }

    @include tablet {
        padding: 16px;
    }

    &__divider {
        width: 100%;
        height: 1px;
        background: $btn-addetional-hover;
    }

    &__divider2 {
        width: 100%;
        height: 1px;
        background: #ebebeb;
        margin-top: 16px;
        margin-bottom: 16px;

        @include tablet {
            margin: 0;
        }
    }

    &__title {
        @include t_20;
        color: $text-primary;
        font-weight: 700 !important;
    }
    sup{
        color: #fff;
    }
    &__title5 {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #8a8a8a;
        margin-top: 4px;
        margin-bottom: 30px;
    }

    &__items {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__item {
        display: grid;
        gap: 7px;
        grid-template-columns: auto 5fr auto;

        &:nth-child(2) {
            div {
                &:first-child {
                    margin-top: 1px;
                }
            }
        }

        @include small-desktop {
            grid-template-columns: auto 8fr auto;
        }

        @include mobile {
            grid-template-columns: auto 8fr auto;
        }

        &_title {
            @include t_14;
            color: $text-primary;
        }

        &_subtitle {
            @include t_12;
            color: $text-secondary2;
            margin-top: 2px;
        }

        &_price {
            @include t_14;
            font-weight: 700 !important;
            color: $text-primary;
            margin-left: auto;
        }

        &_discount {
            @include t_14;
            font-weight: 400 !important;
            color: #4db65d;
            margin-left: auto;
        }
    }

    &__sum {
        display: flex;
        justify-content: space-between;

        &_title {
            @include t_14;
            color: $text-secondary2;
        }

        &_cost {
            @include t_20;
            font-weight: 700;
            color: $text-primary;
        }

        &_btn {
            padding: 20px;

            @include small-desktop {
                width: 100%;
            }
        }
    }

    &__personal {
        display: flex;
        gap: 8px;

        &_text {
            @include t_12;
            color: $text-secondary2;

            span {
                border-bottom: 1px solid $text-secondary2;
                display: inline-block;
                cursor: pointer;
            }
        }
    }

    &__promo {
        display: flex;
        flex-direction: column;
        gap: 12px;

        &_btn {
            padding: 12px 24px;
            height: auto;
        }
    }
}

.warning {
    display: flex;
    color: $text-error;
    margin-top: -8px;

    svg {
        margin-right: 5px;
        margin-top: 3px;
    }
}
