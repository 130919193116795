@import '../../../scss/global.scss';

.dropdown {
    height: 40px;
    overflow: hidden;

    &_mobile {
        height: 20px;
    }

    .dropdown_arrow {
        transform: rotate(180deg);
    }

    &_opened {
        height: auto;

        .dropdown_arrow {
            transform: none;
        }

        .dropdown_header {
            border: none;
            padding-bottom: 0;
        }
    }

    &_header {
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        user-select: none;
        border-bottom: 1px solid #cfcfcf;
        padding-bottom: 16px;
    }

    &_headerMobile {
        height: 22px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        user-select: none;
        padding-bottom: 0;
        border-bottom: none
    }

    &_title {
        font-size: 18px;
        font-weight: 700;
    }

    &_content {
        &_column {
            display: flex;
            flex-direction: column;
            gap: 12px;
            margin-top: 16px;
        }
    }
}
