@import '../../../scss/global.scss';

.custom_input_container {
    position: relative;

    .custom_input {
        border-radius: 8px;
        border-color: $text-secondary3;
        height: 62px;
        padding: 21px 16px;
        color: $text-primary;
        @include t_16;

        &_password {
            position: absolute;
            top: 24px;
            right: 10px;
        }

        &_error {
            border-color: $text-error;
        }

        &:hover,
        &:focus {
            border-color: $text-secondary2 !important;
        }

        &_blocked {
            background-color: #f8f8fa;
            border-color: $text-secondary3;
            color: $text-secondary3;
        }

        &_search_error {
            margin-bottom: 6px;
            border: 2px solid $bg-primary2;
        }

        &_with_text~.custom_input_placeholder {
            display: block;
            z-index: 1;
        }

        &_with_text {
            padding-top: 40px;

            :global {
                .ant-input-suffix {
                    margin-top: -10px !important;
                }
            }
        }

        &_with_success {
            border-color: $text-done;
        }

        &_with_icon input {
            height: 18px;

            &::placeholder {
                color: $text-secondary2;
                font-weight: 400;
                font-size: 16px;
            }
        }

        &::placeholder {
            color: $text-secondary2;
            font-weight: 400;
            font-size: 16px;
            max-width: 100%; // Ширина адаптируется
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &_with_text {
            &::placeholder {
                color: $bg-secondary2;
                font-weight: 400;
                font-size: 16px;
            }
        }
    }

    .error_text {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: $text-error;
        margin-top: 2px;
    }

    .success_text {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: $text-done;
        margin-top: 2px;
    }

    .search_error_text {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        padding: 4px 8px;
        background: $bg-primary2;
        border-radius: 4px;
        color: $text-secondary;
    }

    .custom_input_placeholder {
        display: none;
        position: absolute;
        top: 12px;
        left: 16px;
        font-size: 14px;
        color: $text-secondary2;
    }

    :global {
        .ant-input-affix-wrapper {
            transition: none;
        }
    }
}

.adaptive {
    max-width: calc(100% - 16px * 2); // Ширина адаптируется
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}