@import '../../../scss/global.scss';

.sorting{
    position: absolute;
    height: 100%;
    left: 0;
    right: 0;
    background: #fff;
    top: 0;
    z-index: 100;
    padding: 0 16px;

  &_header{
    display: flex;
    height: 56px;
    align-items: center;
    justify-content: center;

    svg{
      position: absolute;
      top: 16px;
      left: 16px;
      cursor: pointer;
    }

    p{
        font-weight: 700;
    }
  }

  &_item{
    margin-bottom: 16px;
    cursor: pointer;

    &_active{
      color: $text-actions;

      svg path{
        fill: $text-actions;
      }
    }

    &_arrow{
      margin-left: 7px;

      &_asc svg{
        transform: rotate(180deg);
      }
    }

    &_container{
      display: flex;
      margin-bottom: 4px;
    }
  }

  &_button{
    position: absolute;
    bottom: 16px;
    left: 16px;
    right: 16px;
  }
}
