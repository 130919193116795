@import "../../../scss/global.scss";

.tabs_items {
  :global {
    .ant-tabs-nav-list {
      width: 100%;
    }

    .ant-tabs-nav {
      margin: 0;
    }

    .ant-tabs-nav-list {
      justify-content: space-between;
    }

    .ant-tabs-tab {
      width: 33%;
      border-radius: 16px 16px 0 0 !important;
      height: 79px;
      border: none !important;
      transition: none !important;
      background-color: #fff !important;

      @include small-desktop{
        height: auto;
      }

      &:hover{
        color: #000;
      }

      .ant-tabs-tab-btn {
        background-color: #fff;
        color: #000;
        height: 79px;
        width: 100%;
        padding-top: 16px;
        transition: none !important;
        text-shadow: none;

        @include small-desktop{
          height: auto;
        }


        &:hover{
          color: #ed7e2e;
        }
      }

      &-active {
        background: #ed7e2e !important;

        .ant-tabs-tab-btn {
          background-color: #ed7e2e;
          color: #fff !important;
        }
      }
    }

    .ant-tabs-tabpane-active {
      background: #ed7e2e;
    }

    .ant-tabs-tabpane {
      padding: 32px 16px;
      border-radius: 0 0 16px 16px;
    }

    .ant-tabs-content{
      @include small-desktop{
        position: static;
      }
    }

  }

}

.tabs_item {
  background-color: inherit;
  color: inherit;
  font-weight: 700;
  @include t_16;
  line-height: 19px;
  text-shadow: none;
  height: 79px;

  .tabs_item_text{
    background-color: inherit;
    margin-top: 4px;
    color: inherit;
    white-space: pre-wrap;
    font-weight: 700;
  }

  svg{
    background-color: transparent;
  }
}

.status_text{
  position: absolute;
  right: 16px;
  background-color: #0094FF;
  width: 47px;
  height: 20px;
  font-size: 10px;
  text-align: center;
  font-weight: 700;
  border-radius: 24px;
  color: #fff;
  top: 16px;
}
