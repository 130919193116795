.wrapper {
    display: flex;
    gap: 6px;
    align-items: center;
}

.pin {
    width: 14px;
    height: 14px;
    border-radius: 14px;
    display: inline-block;
}

.payed {
    background: #4db65d;
}

.canceled {
    background: #ff6240;
}

.created {
    background: #007aff;
}

.processing {
    background: #007aff;
}
