@import '../../scss/global.scss';

.booking {
    background: $bg-secondary;
    position: relative;
    padding-top: calc($content-padding-top-desktop + 32px);

    @include small-desktop {
        padding-top: calc($content-padding-top + 16px);
    }

    &__form {
        display: flex;
        flex-direction: column;
        gap: 21px;

        @include tablet {
            gap: 16px;
        }
    }

    &__rightBlock {
        position: sticky;
        top: calc(32px + $content-padding-top-desktop);
        height: 100vh;

        @include small-desktop {
            position: static;
            height: auto;
        }


    }

    &__leftBlock {
        display: flex;
        flex-direction: column;
        gap: 21px;

        &_back {
            display: flex;
            gap: 9px;
            align-items: center;
            cursor: pointer;

            button {
                @include t_14;
                color: $text-actions;
                background-color: transparent;

            }
        }
    }

    &__wrapper {
        display: grid;
        grid-template-columns: 3fr 1fr;
        gap: 16px;

        @include small-desktop {
            grid-template-columns: 1fr;

        }
    }

    &__tickets {
        filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1));
    }

    &__top {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 40px;
        background: $bg-primary2;
        border-radius: 24px;
        position: relative;

        @include tablet {
            padding: 16px 16px 100px;
        }

        &_title {
            @include t_48;
            color: $text-secondary;
        }

        &_subtitle {
            @include t_18;
            color: $text-secondary;
        }

        &_img {
            position: absolute;
            bottom: 0;
            right: 24px;

            @include tablet {
                max-width: 100px;
            }
        }
    }


}

.passengers {
    background: $bg-primary;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @include tablet {
        padding: 16px;
        gap: 16px;
    }

    &__title {
        @include t_24;
        color: $text-primary;
    }

    &__subtitle {
        @include t_16;
        color: $text-primary;
        margin-top: 4px;
    }

    &__inputs {
        margin-top: 16px;
        display: grid;
        grid-template-columns: 4fr 1fr 3fr;
        gap: 6px;
    }
}
