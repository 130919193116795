@import '../../../scss/global.scss';

.bookingSuccess {
    &__top {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 40px;
        background-color: $bg-primary;
        border-radius: 24px;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);

        @include tablet {
            padding: 16px;
        }


        &_img {
            max-width: 96px;
        }

        &_title {
            @include t_48;
            color: $text-primary;
            margin-top: 16px;
            text-align: center;
        }

        &_text {
            @include t_16;
            color: $text-primary;
            margin-top: 8px;
            text-align: center;

            span {
                color: $text-actions;
            }
        }

        &_download {
            @include t_16;
            color: $text-primary;
            margin-top: 26px;
            font-weight: 700 !important;
            position: relative;
            width: fit-content;
            text-align: left;

            @include tablet {
                padding-left: 20px;
            }

            &::before {
                content: url(../../../assets/icons/clock-success.svg);
                position: absolute;
                top: 0;
                left: -25px;

                @include tablet {
                    left: -5px;
                }

                @include mobile {
                    left: -25px;
                    top: 5px;
                }
            }

            @include mobile {
                width: 80%;
                padding-left: 0;
            }
        }

        &_buttons {
            display: flex;
            gap: 16px;
            margin-top: 14px;

            @include tablet {
                flex-direction: column;
                width: 100%;

                button {
                    width: 100%;
                }
            }
        }
    }
}
