@import '../../../scss/global.scss';

.info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 20px;

    &__tickets {
        filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1));
    }
}

