.wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 21px;
}

.text{
    display: flex;
    gap: 12px;
    align-items: center;
}