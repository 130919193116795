@import '../../../../scss/global.scss';

.empty {
    display: flex;
    flex-direction: column;
    padding: 40px;
    position: relative;
    background-color: $bg-primary2;
    border-radius: 16px;

    @include tablet {
        padding: 20px 18px 92px;
    }

    &__title {
        @include t_32;
        color: $text-secondary;
    }

    &__text {
        margin-top: 8px;
        color: $text-secondary;
        @include t_14;
        max-width: 80%;

        @include tablet {
            max-width: 100%;
        }
    }

    &__btn {
        margin-top: 18px;
        @include t_14;
        font-weight: 700 !important;
        height: auto;
        width: fit-content;
        padding: 12px 24px;

        @include small-desktop {
            margin-top: 16px;
        }

        @include tablet {
            width: 100%;
        }
    }

    img {
        position: absolute;
        max-width: 96px;
        right: 24px;
        top: 50%;
        transform: translateY(-50%);

        @include tablet {
            width: 10%;
            bottom: 0;
            right: 0;
            top: auto;
            transform: translateY(0);
        }

        @include mobile {
            width: 20%;
        }
    }
}