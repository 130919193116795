@import 'scss/global.scss';

.banner {
    width: 100%;
    padding: 0 32px;
    border-radius: 24px;
    display: flex;

    @include tablet {
        padding: 0 25px;
    }

    @include mobile {
        padding: 0 15px;
    }
}

.primary {
    background: $bg;
}

.dark {
    background: $bg-primary2;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 25px 20px;

    @include tablet {
        padding: 25px 20px;
    }
}

.title {
    color: $text-secondary;
}

.desc {
    color: $text-secondary;
}

.imgWrapper {
    border-right: 1px;
    border-color: #D9D9D940;
    border-style: solid;
    display: flex;
    align-items: center;

    @include mobile {
        border: none;
    }
}

.img {
    max-height: 100px;
    height: 100%;
    padding-right: 20px;

    @include mobile {
        display: none;
    }
}