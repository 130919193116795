@import "scss/global.scss";

.form{
    display: flex;
    gap: 6px;
    justify-content: space-between;
    align-items: center;

    @include tablet{
        flex-direction: column;
    }


    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        color: $text-black;
        font-weight: 700;
        background: none !important;
        transition: unset;
    }
}

.select{
    width: 37%;
    @include tablet{
        width: 100%;
    }
}

.button{
    width: calc(26% - 12px);
    @include tablet{
        width: 100%;
    }
}
