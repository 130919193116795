@import '../../../../scss/global.scss';

.passengers {
    background: $bg-primary;
    border-radius: 16px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border: 1px solid $btn-addetional-hover;

    &__profile {
        padding: 24px;
    }

    @include tablet {
        padding: 16px;
    }

    &__top {
        display: flex;
        flex-direction: column;
        gap: 4px;
        background-color: $bg-secondary;
        padding: 16px;
        border-radius: 16px;
        position: relative;

        img {
            position: absolute;
            bottom: 10px;
            right: 16px;
            z-index: 1;


        }

        &_title {
            color: $text-primary;
            @include t_20;
            z-index: 2;
            font-weight: 700 !important;
        }

        &_subtitle {
            color: $text-secondary2;
            @include t_14;
        }

    }

    &__title {
        color: $text-primary;
        @include t_24;
        font-weight: 700 !important;
    }

    &__content {
        display: flex;
        justify-content: space-between;
        max-width: 80%;

        @include tablet {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 16px;
            max-width: 100%;

        }

        &_item {
            display: flex;
            flex-direction: column;
            gap: 8px;
            color: $text-primary;
        }

        &_text {
            color: $text-primary;
            @include t_14;
            font-weight: 700 !important;
        }
    }
}