@import '../../../scss/global.scss';

.direction {
    background: $bg-primary2;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 24px;
    width: 100%;
    position: relative;
    overflow: hidden;

    @include tablet {
        padding: 16px 16px 100px;
    }

    &__title {
        color: $text-secondary;
        @include t_32;
    }

    &__subtitle {
        color: $text-secondary;
        @include t_18;

    }

    &__img {
        position: absolute;
        bottom: 0;
        right: 22px;
        max-width: 170px;

        @include tablet {
            max-width: 120px;
            right: 0;
        }

        @include mobile {
            max-width: 100px;
        }
    }
}