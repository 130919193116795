@import '../../../scss/global.scss';

.booking {
    padding: 64px 0;
    position: relative;
    background-color: $bg-secondary;

    @include tablet {
        padding: 0 0 40px 0;
    }

    &__bg {
        position: absolute;
        z-index: -1;
        right: 0;
        bottom: 0;
        padding: 0;

        img {
            width: 200px;

            @include tablet {
                width: 110px
            }

        }
    }

    &__title {
        @include t_48;
    }

    &__content {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
        gap: 16px;
        margin-top: 32px;

        @include small-desktop {
            grid-template-columns: repeat(auto-fit, minmax(530px, 1fr));
        }

        @include tablet {
            display: flex;
            flex-direction: column;
            gap: 16px;

        }

        &_item {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto 1fr;
            gap: 16px;

            @include tablet {
                display: flex;
                flex-direction: column;
                gap: 16px;

            }

            &:nth-child(2) {
                position: relative;
                display: flex;
                background-color: $bg;
                border-radius: 24px;
                padding: 32px;
                flex-direction: column;
                align-items: flex-start;
                z-index: 10;
                gap: 0;

                @include tablet {
                    padding: 18px 20px 150px;
                }

            }
        }
    }

    &__list {
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        max-width: 65%;

        &_item {
            display: flex;
            gap: 10px;
        }

        &_text {
            @include t_16;
            color: $text-secondary;
        }

        &_img {
            max-width: 12px;

        }

        @include small-desktop {
            max-width: 100%
        }
    }


    &__item {
        padding: 36px;
        border-radius: 24px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        background: $bg-primary;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);

        border-radius: 24px;

        @include tablet {
            padding: 18px 20px;
        }


        &_listTitle {
            @include t_16;
            color: $text-secondary;
            margin-top: 14px;
        }



        &_title {
            color: $text-secondary;
            @include t_32;
        }

        &_text {
            @include t_20;
            color: $text-primary;
        }

        &_img {
            height: 40px;

            svg {
                height: 40px;
                width: 40px;
            }
        }

        &:nth-child(3) {
            grid-column: 1 / 3;
            grid-row: 2 / 3;
            background: $bg-primary2;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);

            .booking__item_text {
                @include t_24;
                color: $bg-secondary;
            }
        }
    }

    &__example {
        display: flex;
        gap: 10px;
        align-items: center;
        margin-top: 50px;

        @include small-desktop {
            margin-top: 19px;
        }

        &_img {
            max-width: 10px;
        }

        &_text {
            @include t_16;
            color: $bg-secondary;
        }
    }

    &__btn {
        background: $btn-secondary;
        border-radius: 12px;
        padding: 20px 24px;
        color: $text-secondary;
        margin-top: 8px;

    }

}
