@import "../../../scss/variables.scss";

.custom_button {
    height: 59px;
    border-radius: 12px;
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    padding: 0 39px;
    justify-content: center;

    span {
        background: none;
        font-size: 16px;
    }

    svg {
        margin-right: 4px;
    }

    &_small {
        height: 41px;
        padding: 0 24px;
        font-size: 14px;
    }

    &_primary {
        background: $btn-primary;
        border: none;

        span {
            color: $text-secondary;
        }

        &:not(:disabled):hover {
            background: $btn-primary-hover!important;
            border-color: $btn-primary-hover !important;
        }
    }

    &_lined {
        background: transparent;
        color: $btn-primary;
        border: 1px solid $btn-primary;

        span {
            color: $btn-primary;
        }

        &:hover {
            border-color: $btn-primary-hover !important;

            span {
                color: $btn-primary-hover;
            }
        }
    }

    &_lined_black {
        color: $text-primary;
        border: 1px solid $text-primary;
        background: transparent;

        &:hover {
            border-color: $text-primary !important;
            color: $text-primary !important;
        }
    }

    &_secondary {
        background: $btn-secondary;
        border: none;

        span {
            color: $text-secondary;
        }

        &:not(:disabled):hover {
            background: $btn-secondary-hover;
            border-color: $btn-secondary-hover !important;
        }
    }

    &_full_width {
        width: 100%;
        justify-content: center;
    }

  &_disabled{
    background-color: $btn-addetional !important;
    color: $text-secondary3 !important;

    span{
      color: $text-secondary3 !important;
    }
  }
}
