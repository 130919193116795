@import '../../../scss/global.scss';

.accordion {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: 32px;
    user-select: none;
    background: $bg-primary;
    border-radius: 12px;
    z-index: 1;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);

    @include tablet {
        padding: 16px;
    }

    &__longText {
        @include tablet {
            padding: 25px 16px;
        }
    }



    &__icon {
        transition: .3s all;
        margin-right: 20px;
        z-index: 3;
        width: 16px;
        position: relative;

        &::after {
            content: "";
            border-radius: 50%;
            background: $btn-addetional;
            width: 40px;
            height: 40px;
            position: absolute;
            top: -12px;
            left: -12px;
            transition: 0
        }



        &_outer {
            transition: .3s all;
            position: relative;

            &::after {
                content: '';
                border-radius: 50%;
                background: $text-actions;
                width: 40px;
                height: 40px;
                position: absolute;
                top: -12px;
                left: -12px;
                transition: .3s all;
                z-index: -1;
            }
        }

        &_active {
            transition: .3s all;
            transform: rotate(-90deg);
        }
    }

    &__text {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

    }

    &__title {
        color: $text-primary;
        @include t_20;
        margin-right: 18px;
    }

    &__content {
        height: 0;
        overflow: hidden;
        transition: .3s all;
        color: $text-primary;
        margin-top: 0;
        opacity: 0;
        max-width: 85%;
        @include t_16;
        white-space: pre-line;


        &_active {
            height: auto;
            transition: .3s all;
            margin-top: 12px;
            opacity: 1;
        }
    }
}
