@import "../../../scss/global.scss";

.search_panel {
    display: flex;
    justify-content: space-between;
    gap: 6px;

    @include small_desktop {
        display: block;
    }

    &_opened_header,
    &_opened_input {
        display: none;

        @include small-desktop {
            display: block;
        }
    }
    &_swap_btn {
        position: relative;
        width: 1px;
        margin: auto;
        div {
            width: 30px;
            height: 30px;
            position: absolute;
            top: 0;
            bottom: 0;
            left: -15px;
            right: 0;
            margin: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            img {
                transition-duration: 0.5s;
                transition-property: transform;
                transition-timing-function: cubic-bezier(
                    0.33,
                    0.96,
                    0.49,
                    1.01
                );
                z-index: 2;
            }
            &:after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 1;
                margin: auto;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background-color: #fff;
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.45);
                transition: all 0.5s;
            }
            &:hover {
                width: 32px;
                height: 32px;
                left: -16px;
                &:after {
                    transform: scale(1.1);
                }
            }
            @media (min-width: 1023px) {
                img {
                    &:first-child {
                        transform: rotate(180deg);
                    }
                }
                &:hover {
                    img {
                        transform: translateX(-2px);
                        &:first-child {
                            transform: translateX(2px) rotate(180deg);
                        }
                    }
                }
            }
            @media (max-width: 1024px) {
                img {
                    transform: rotate(-90deg) translateY(3px);
                    &:first-child {
                        transform: rotate(90deg) translateY(2px);
                    }
                }
                &:hover {
                    img {
                        transform: rotate(-90deg) translateY(3px)
                            translateX(-2px);
                        &:first-child {
                            transform: rotate(90deg) translateY(2px)
                                translateX(-2px);
                        }
                    }
                }
            }
        }
    }

    &_opened_header {
        @include small-desktop {
            display: flex;
            height: 56px;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-weight: 700;
            position: fixed;
            right: 0;
            left: 0;
            top: 0;
            background: $bg-primary;
            z-index: 1;
        }
        p {
            @include t_16;
        }

        svg {
            transform: rotate(-90deg);
            position: absolute;
            top: 24px;
            left: 16px;
        }
    }

    &_input {
        width: 232px;
        position: relative;

        &_container {
            position: relative;

            svg {
                position: absolute;
                right: 16px;
                top: 21px;
            }
        }

        @include small-desktop {
            position: static;
        }

        &_with_text,
        &_right_with_text {
            padding-top: 20px;
        }

        &_placeholder,
        &_right_placeholder {
            position: absolute;
            top: 12px;
            left: 16px;
            z-index: 1;
            color: $text-secondary2;
            @include t_14;
        }

        &_right_placeholder {
            @include small_desktop {
                top: 18px;
            }
        }

        &:last-of-type {
            clear: both;
        }

        @include small_desktop {
            width: 100%;
            margin-top: 6px;
        }

        &_left {
            @include small_desktop {
                width: 49%;
                float: left;
            }
        }

        &_right {
            @include small_desktop {
                width: 49%;
                float: right;
                margin-top: 0;
                margin-bottom: 6px;

                svg {
                    top: 26px;
                }
            }
        }

        input {
            width: 100%;
            height: 62px;
            border: 1px solid $bg-secondary2;
            border-radius: 8px;
            padding-left: 16px;

            &:focus {
                border: 1px solid #282828;
            }
        }

        .places_list {
            background: $bg-primary;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.16);
            border-radius: 12px;
            padding: 8px 0;
            width: 340px;
            margin-top: 8px;
            display: none;
            position: absolute;
            z-index: 1;

            @include small-desktop {
                z-index: 101;
                top: 0;
                width: 100%;
                left: 0;
                min-height: 100vh;
                margin-top: 0;
                padding-top: 56px;
                padding-bottom: 75px;
            }

            &_opened {
                display: block;
            }

            &_city {
                padding: 10px 24px;
                position: relative;

                &:hover {
                    background: $bg-secondary;
                }

                &_name {
                    font-weight: 700 !important;
                    font-size: 14px;
                    line-height: 17px;
                    margin-bottom: 2px;
                }

                &_country {
                    @include t_12;
                }

                &_label {
                    @include t_14;
                    color: $text-secondary2;
                    position: absolute;
                    right: 24px;
                    top: 0;
                    bottom: 0;
                    line-height: 53px;
                }
            }

            &_airport {
                position: relative;
                height: 52px;
                display: flex;
                align-items: center;
                padding-left: 24px;

                svg {
                    margin-top: 6px;
                    margin-right: 9px;
                }

                &:hover {
                    background: $bg-secondary;
                }
            }

            &__not_found {
                padding-left: 16px;
            }
        }

        .passengers_list {
            position: absolute;
            background: $bg-primary;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.16);
            border-radius: 12px;
            width: 320px;
            display: none;
            padding: 26px 0;
            top: 70px;
            z-index: 1;

            @include small-desktop {
                z-index: 101;
                top: 0;
                width: 100%;
                left: 0;
                min-height: 100vh;
                padding-top: 56px;
                padding-bottom: 60px;
            }

            &_opened {
                display: block;
            }

            .passenger_type {
                position: relative;
                padding: 10px 24px;

                &_name {
                    font-weight: 700 !important;
                    @include t_14;
                    margin-bottom: 4px;
                }

                &_label {
                    @include t_12;
                }

                .plus_minus {
                    position: absolute;
                    right: 24px;
                    top: 8px;
                }
            }

            .class_tags {
                padding-left: 24px;
                margin-top: 18px;
            }
        }

        &_error {
            background: $bg-primary2;
            padding: 4px 8px;
            color: $text-secondary;
            @include t_12;
            border-radius: 4px;
            margin-top: 2px;
            display: inline-block;
        }
    }

    &_opened_input {
        margin-left: 16px;
        width: calc(100% - 32px) !important;
        margin-bottom: 16px;
    }

    .calendar_inputs {
        position: relative;
    }

    .calendars {
        position: absolute;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.16);
        border-radius: 12px;
        background: $bg-primary;
        top: 70px;
        z-index: 1;
        padding-bottom: 70px;

        @media screen and (max-width: 1150px) {
            left: -65px;
        }

        @include small-desktop {
            top: 0;
            z-index: 101;
            width: 100%;
            left: 0;
            min-height: 100vh;
            padding-top: 56px;
        }

        &_button {
            position: absolute;
            bottom: 24px;
            left: 24px;
            right: 24px;
        }

        &_inputs {
            justify-content: space-between;
            width: 100%;
            padding-right: 16px;
            display: none;

            @include small-desktop {
                display: flex;
            }

            input {
                width: 48%;
            }
        }

        &_container {
            display: flex;
            justify-content: space-between;

            @include mobile {
                flex-direction: column;
                align-items: center;
                margin-bottom: 16px;
            }
        }
    }

    .search_button {
        @include small_desktop {
            width: 100%;
            margin-top: 6px;
        }
    }

    .apply_button_container {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        width: calc(100% - 32px);
        margin: auto;
        background: #fff;
        z-index: 1;
        display: none;

        @include small-desktop {
            display: block;
            padding-bottom: 16px;
        }

        .apply_button {
            width: 100%;
        }
    }
}

.hasError {
    border: 2px solid $bg-primary2 !important;
}
