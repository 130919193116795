@import '../../../scss/global.scss';

.offer {
    padding-top: calc($content-padding-top-desktop + 16px);

    @include small-desktop {
        padding-top: calc($content-padding-top + 16px);
    }

    &__list {
        li {
            list-style: decimal;
        }
    }
}

.policy__item_title{
    color: $text-primary;
    @include t_48;
    margin-top: 40px;
    word-wrap: break-word;

    @include tablet {
        margin-top: 32px;
    }
    margin-bottom: 32px !important;
}

p{
    color: #000000;
    font-weight: 400;
    font-size: 16px;
}