@import "../../../scss/global.scss";

.passenger_spinner {
    margin-left: 16px;
}

.passengers_page {
    flex: 1;
    padding-bottom: 16px;

    .passenger {
        background: $bg-primary;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        border-radius: 24px;
        padding: 24px;
        margin-top: 16px;

        @include small-desktop {
            padding: 16px;
        }

        &_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 16px;
            background: $bg-secondary;
            padding: 16px;
        }

        &_name {
            @include t_20;
            margin-bottom: 4px;
        }

        &_type {
            color: $text-secondary2;
            @include t_14;
        }

        &_info {
            display: flex;
            padding-top: 16px;

            @include tablet {
                flex-wrap: wrap;
            }

            &_item {
                width: 48%;

                @include tablet {
                    margin-bottom: 16px;
                }

                &_title {
                    @include t_14;
                    margin-bottom: 4px;
                }

                &_value {
                    @include t_14;
                    font-weight: 700;

                    @include tablet {
                        font-weight: 700;
                    }
                }
            }
        }

        &_edit {
            display: flex;
            margin-top: 16px;

            img {
                margin-right: 4px;
            }

            a {
                color: $text-actions;
            }
        }
    }

    &_show_more {
        width: 100%;
        margin-top: 16px;
    }

    &__not_verified{
      @include t_24;
      margin-top: 16px;
    }
}

.passenger_form {
    width: 100%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    background: $bg-primary;
    padding: 32px;
    margin-top: 35px;
    position: relative;

    &_breadcrumb {
        position: absolute;
        top: -32px;
        left: 0;
    }

    @include small-desktop {
        padding: 16px;
    }

    h1 {
        @include t_32;
        margin-bottom: 24px;
    }

    &_row {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        gap: 16px;

        @include tablet {
            flex-direction: column;
            margin-top: 0;
        }
    }

    &_element {
        width: 49%;

        @include tablet {
            width: 100%;
            min-height: 62px;
            margin-top: 16px;
        }
    }

    &_checkbox {
        margin-top: 8px;
    }

    &_title {
        display: flex;
        @include t_16;
        font-weight: 700;
        margin-top: 16px;
        svg {
            margin-right: 9px;
        }
    }

    &_buttons {
        display: flex;
        margin-top: 24px;

        @include small-desktop {
            flex-direction: column;
            margin-top: 0;
        }

        @include tablet {
            margin-top: 8px;
            &.withErrors {
                margin-top: 8px;
            }
        }

        &_left {
            margin-right: 16px;

            @include small-desktop {
                margin-right: 0;
                margin-top: 16px;
                margin-bottom: 16px;
            }
        }
    }

    &_warning {
        display: flex;
        margin-top: 9px;
        color: $text-error;
    }

    &_help_text {
        margin-top: 8px;
        @include t_14;
        color: $text-secondary2;

        @include tablet {
            &.withErrors {
                margin-top: 20px;
            }
        }
    }
}
