@import '../../../scss/global.scss';

.spinner {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 10px 0;
  justify-content: center;
}

.lds_ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    margin: 8px;
    border: 4px solid rgb(255, 72, 0);
    border-radius: 50%;
    animation: lds-ring 1.5s cubic-bezier(0.5, 0, 0.5, 1) infinite,
    colorChange linear 5s infinite;

    &:nth-child(1) {
      animation-delay: -0.15s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.45s;
    }
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes colorChange {
  0% {
    border-color: rgb(245, 189, 116) transparent transparent transparent;
  }

  50% {
    border-color: rgb(255, 255, 255) transparent transparent transparent;
  }

  100% {
    border-color: rgb(245, 189, 116) transparent transparent transparent;
  }
}