@import "../../../scss/global.scss";

.custom_popup {
    ::-webkit-scrollbar {
        width: 0!important;
    }
    &_partner {
        max-width: 668px !important;
        width: 100% !important;
        overflow-y: auto;
        max-height: 90vh !important;
        border-radius: 24px;
        position: relative;
        top: auto;
        left: auto;
        transform: none;
        pointer-events: all!important;
        -ms-overflow-style: none;
        ::-webkit-scrollbar {
            width: 0!important;
        }

        &_extended {
            max-height: 90vh !important;
        }

        &_wrap {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        :global {
            .ant-modal-content {
                border-radius: 24px;
                padding: 0 32px 32px !important;

                @include small-desktop {
                    padding: 0 16px 24px !important;
                }
            }



            .ant-modal-close {
                position: sticky;
                top: 0px !important;
                right: 36px !important;
                height: 56px;
                background: white;
                display: flex;
                width: 100%;
                justify-content: end;
                align-items: end;
                padding: 32px 0 0;

                &:hover {
                    background: white;
                }

                @include small-desktop {
                    padding: 24px 0 0;
                }
            }
        }

        @include tablet {
            max-width: calc(100% - 32px) !important;
            margin: 0 16px !important;
        }
    }

    :global {
        .ant-modal-content {
            border-radius: 24px;
            padding: 32px;
            pointer-events: all!important;

            @include small-desktop {
                padding: 16px 16px 24px;
            }
        }


        .ant-modal-title {
            text-align: center;
            margin-bottom: 24px;
            
            @include small-desktop {
                margin-top: 25px;
            }

            @include tablet {
                margin-top: 25px;
            }

            &_text_left {
                text-align: left;
            }

            p {
                @include t_32;
                color: $text-primary;
            }

            a {
                color: $text-actions-hover;
            }

            span {
                font-weight: normal;
            }
        }

        .ant-modal-footer div {
            display: flex;
            width: 100%;
            justify-content: space-between;

            button {
                width: 48%;
            }
        }
        .ant-modal-footer div.footer_center {
            justify-content: center;
        }

        .ant-modal {
            width: 1008px !important;
        }

    }
}
