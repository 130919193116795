@import '../../../scss/global.scss';

.ticket {
    padding: 24px;
    background: $bg-primary;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: #FFFFFF;

    @include tablet {
        gap: 8px;
        padding: 16px;
    }

    @include small-desktop {
        padding: 16px;
    }

    &__mainFull {
        @include small-desktop {
            padding: 24px !important;
        }

        @include tablet {
            padding: 16px !important;
        }
    }

    &__full {
        width: 100%;
    }


    &__header {
        display: flex;
        gap: 10px;
        align-items: center;

        &_icon {}

        &_iconReverse {
            transform: scaleX(-1)
        }

        &_direction {
            @include t_12;
            color: $text-secondary2;
        }

        &_company {
            display: flex;
            padding: 4px;
            gap: 4px;
            align-items: center;
            background: #EFEFEF;
            border-radius: 24px;
            color: $text-primary;
            @include t_12;

            img {
                border-radius: 12px;
            }
        }

        &_booking_date{
            color: $text-secondary2;
        }
    }

    &__tooltip {

        &_item {
            width: 100%;
            border: 2px solid $bg;
            background: $bg;

            &_transfer {
                border: 1px solid $icon-secondary;
                margin-top: 2px;
                width: 100%;

                &_mobile {
                    width: 33%;

                    div {
                        width: 100%;
                    }
                }
            }

            &_container{
                height: 16px;
                display: flex;
                align-items: center;
                width: 33%;

                &__full{
                    width: 100%;
                }
            }
        }
    }

    &__main {
        display: flex;
        justify-content: space-between;
        gap: 12px;

        @include small-desktop {
            display: block;
        }

        &_tooltip {
            display: flex;
            gap: 2px;
            align-items: center;

            @include small-desktop {
                display: none;
            }

            &__mobile {
                display: none;
                margin-top: 24px;
                margin-bottom: 24px;

                @include small-desktop {
                    display: flex;
                }
            }
        }

        &_btn {
            background: 0;
            margin-right: auto;
            color: $text-actions;
            @include t_14;
            display: flex;
            gap: 9px;
            align-items: center;
        }

        &_rightBtn {
            background-image: url('../../../assets/icons/arrow-bottom.svg');
            background-repeat: no-repeat;
            width: 15px;
            height: 9px;
        }

        &_rightBtnClosed {
            background-image: url('../../../assets/icons/arrow-bottom.svg');
            background-repeat: no-repeat;
            width: 15px;
            height: 9px;
            transform: rotate(180DEG);
        }

        &__info {
            display: flex;
            gap: 12px;
            flex: 1;

            & > div{
                width: 33%;

                @include small-desktop{
                    width: 50%;
                }
            }
        }

        &_fullTime {
            div {
                &:nth-child(2) {
                    margin-left: auto;

                    @include small-desktop {
                        margin-left: 3px;
                        margin-right: 3px;
                    }
                }

                &:nth-child(3) {
                    margin-right: auto;

                }

                &:nth-child(5) {
                    margin-right: 24px
                }
            }

            @include small-desktop {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 0 !important;

                div {
                    &:nth-child(4) {
                        grid-column: 1 / 3;
                    }

                    &:nth-child(5) {
                        grid-row: 3;
                    }
                }

            }
        }
    }

    &__to {
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
        border-bottom: 1px solid #EBEBEB;
    }

    &__from {
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;


        &__with__button{
            border-radius: 0 0 0 24px !important;

            @include small-desktop{
                border-radius: 0 !important;
            }
        }

        &__single {
            border-radius: 24px;

            &__with__button {
                border-radius: 24px 0 0 24px;
                flex: 1;

                @include small-desktop {
                    border-radius: 24px 24px 0 0;
                }
            }
        }
        &__with__button{
            border-radius: 0 0 0 0;

            @include small-desktop{
                border-radius: 24px 24px 0 0;
            }
        }
    }
    &__with__button{
        border-radius: 24px 0 0 0;

        @include small-desktop{
            border-radius: 24px 24px 0 0;
        }
    }

    &__backTicket {

        &__with__button {
            border-radius: 0 0 0 24px;
            @include small-desktop {
                border-radius: 0;
            }
        }
    }
}
