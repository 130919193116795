@import "../../../scss/variables.scss";

.custom_radio_group{
  :global{
    .ant-radio-inner{
      height: 24px;
      width: 24px;
      border-color: $bg-secondary2;
    }

    .ant-radio-checked .ant-radio-inner{
      background: url("../../../assets/icons/checked.svg");
      border: none;

      &:after{
        display: none;
      }
    }

    .ant-radio-wrapper:hover .ant-radio-inner{
      border-color: $bg-secondary2;
    }
  }
}