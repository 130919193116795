@import '../../../../scss/global.scss';

.documents {
    background: $bg-primary;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 32px;
    display: flex;
    flex-direction: column;

    @include tablet {
        padding: 16px;
    }

    &__text {
        @include t_14;
        color: $text-primary;
        margin-top: 16px;

    }

    &__title {
        color: $text-primary;
        @include t_24;
        font-weight: 700 !important;
    }

    &__subtitle {
        color: $text-primary;
        @include t_14;
        margin-top: 16px;

    }

    &__buttons {
        display: flex;
        gap: 24px;
        margin-top: 10px;
        
        @include tablet {
            flex-direction: column;
            gap: 12px;
        }
        button:disabled {
            background-color: #ccc !important;
        }
    }

    &__issuing {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__btn {
        display: flex;

        @include t_14;
        font-weight: 700 !important;

        div {
            margin-top: 3px;
        }
    
        @include small-desktop {
            width: 100%;
        }

        @include tablet {
            width: 100%;
        }    
    }
    
}

.status {
    background: $bg-primary;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include tablet {
        padding: 16px;
    }

    &__issued {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &_text {
            @include t_14;
            color: $text-secondary2;

        }

        &_doc {
            display: flex;
            align-items: center;
            padding: 8px 12px;
            gap: 8px;
            background: $bg-secondary;
            border-radius: 8px;
            width: fit-content;

            @include tablet {
                width: auto;
            }
        }
    }
}
