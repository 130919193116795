.radio_label {
    &_wrapper {
        width: 200px;
        padding: 10px 5px;
        user-select: none;
        @media (max-width:460px) {
            width: 170px;
        }
        @media (max-width:360px) {
            width: 100%;
        }

    }
    &__inner {
        padding: 12px;
        border-radius: 4px;
        &.active {
            background-color: rgba(0, 108, 228, 0.06);
        }
        &:hover {
            background-color: rgba(0, 108, 228, 0.06);
        }
        transition: all 0.3s;
        cursor: pointer;
    }

    &__title {
    }
    &__subtitle {
        margin-top: 5px;
    }
}
