.partner_cabinet_page {
    flex: 1;
}

.partner_cabinet {
    width: 100%;
    background: #fff;
    padding: 32px;
    border-radius: 16px;
    margin-top: 16px;

    @media (max-width: 768px) {
        padding: 16px;
    }

    &__cards {
        padding-bottom: 16px;
        border-bottom: 1px solid #e1e1e1;
        margin-bottom: 16px;
    }

    &__title {
        display: flex;
        align-items: center;

        span {
            font-weight: 700;
            font-size: 16px;
            margin-left: 9px;
        }
    }

    &__button {
        margin-top: 16px;
    }

    h1 {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 19px;
    }

    .deposit_methods {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 16px;
        margin-bottom: -14px;

        .deposit_method {
            width: 49%;
            border: 1px solid #cfcfcf;
            border-radius: 16px;
            padding: 24px;
            margin-bottom: 20px;

            @media (max-width: 768px) {
                width: 100%;
            }

            &__value {
                font-size: 16px;
                font-weight: 700;
                margin-top: 8px;
            }

            &__label {
                color: #8a8a8a;
                font-size: 14px;
                margin-bottom: 8px;
            }

            &__edit {
                color: #ed7e2e;
                margin-right: 4px;
            }
        }
    }
}

.requisites_form {
    width: 100%;
}
