@import '../../scss/global.scss';

.tickets_container {
    position: relative;
    padding-top: calc($content-padding-top-desktop + 16px);

    @include desktop {
        padding: calc($content-padding-top-desktop + 16px) 16px 0 16px;
    }

    @include small-desktop {
        padding: calc($content-padding-top + 16px) 16px 0 16px;
    }
}

.tickets_search_panel {
    background: $bg;
    max-width: 1352px;
    height: 126px;
    margin: auto;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 16px;

    @include small-desktop {
        display: block;
        height: auto;
        padding: 16px;
    }
}

.tickets_page {
    max-width: 1352px;
    margin: auto;
    display: flex;
    padding-top: 40px;
    gap: 16px;
    justify-content: space-between;

    &_filters {
        width: 25%;

        .filters_block {
            margin-bottom: 17px;
            margin-top: 17px;
            border-bottom: 1px solid $bg-secondary2;
        }

        .filter_checkbox {
            display: flex;
            margin-left: 0;
            margin-bottom: 13px;
            height: 24px;
        }

        .time_sliders {
            &_item {
                margin-bottom: 8px;
                padding-right: 8px;

                &_time {
                    display: flex;
                    justify-content: space-between;
                }

                &_slider {
                    margin-left: 10px;
                    width: 90%;

                    @include small-desktop {
                        width: calc(100% - 20px);
                    }
                }

                &_title {
                    display: flex;
                    margin-bottom: 11px;
                    margin-top: 15px;

                    p {
                        @include t_12;
                        color: $text-secondary2;
                        margin-left: 5px;
                    }
                }

                &_name {
                    @include t_14;
                    margin-bottom: 4px;
                }
            }
        }

        @include small-desktop {
            position: absolute;
            top: -100px;
            right: 0;
            background: #fff;
            padding: 156px 16px 16px 16px;
            z-index: 102;
            display: none;
            width: 100%;
            margin-right: 0;

            &_opened {
                display: block;
                padding-bottom: 130px;
            }
        }

        .filter_buttons {
            @include small-desktop {
                background: #fff;
                position: fixed;
                bottom: 0;
                width: 100%;
                right: 0;
                padding: 0 16px;
            }

            .clear_filters {
                margin: 16px auto;
                width: 100%;
            }

            .apply_filters {
                display: none;
                width: 100%;

                @include small-desktop {
                    display: block;
                    margin-bottom: 16px;
                }
            }
        }

        &_header {
            display: none;
            position: relative;

            @include small-desktop {
                display: flex;
                height: 56px;
                align-items: center;
                justify-content: center;
                position: fixed;
                width: 100%;
                background: #fff;
                top: 0;
                z-index: 103;

                p {
                    font-weight: 700;
                    font-size: 16px;
                }

                svg {
                    transform: rotate(-90deg);
                    position: absolute;
                    top: 24px;
                    left: 0;
                }
            }
        }
    }

    &_results {
        width: 75%;

        @include small-desktop {
            width: 100%;
        }

        &_item {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            margin-bottom: 16px;
            filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1));

            .buy_ticket {
                padding: 24px;
                background: $bg-primary;
                border-left: 1px solid $border-grey;
                border-radius: 0 24px 24px 0;
                position: relative;

                @include small-desktop {
                    border-radius: 0 0 24px 24px;
                    padding: 16px;
                    border-top: 1px solid #ebebeb;
                }

                &_price {
                    @include t_24;
                    margin-bottom: 8px;
                }

                &_info {
                    @include t_12;
                    color: $text-secondary2;
                    margin-top: 4px;
                    margin-bottom: 30px;
                }

                sup {
                    color: $text-secondary;
                }

                button {
                    @include small-desktop {
                        width: 100%;
                    }
                }

                &_details {
                    display: flex;
                    color: $text-actions;
                    position: absolute;
                    bottom: 16px;
                    cursor: pointer;

                    svg {
                        margin-left: 6px;
                        transform: rotate(180deg);

                        path {
                            stroke: $text-actions
                        }
                    }

                    &__opened{
                        svg {
                            transform: none;
                        }
                    }
                }
            }

            @include small-desktop {
                display: block;
            }

            &_two{
                flex: 1;
            }
        }

        .open_filters {
            display: none;

            @include small-desktop {
                display: block;
                width: 100%;
                margin-bottom: 24px;
            }
        }
    }

    &_showMore {
        width: 100%;
        margin-top: 16px;
    }

}

.filters_background {
    display: none;
    position: absolute;
    background: $bg-primary2;
    opacity: 0.5;
    top: -122px;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 101;

    @include small-desktop {
        display: block;
    }
}

.notFound {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 16px;
    margin: 0 auto;
    background: $bg-primary;
    border-radius: 24px;
    width: 100%;
    height: fit-content;

    &__img {
        max-width: 64px;
    }

    &__title {
        margin-top: 16px;
        @include t_24;
        font-weight: 700 !important;
        text-align: center;

    }

    &__text {
        text-align: center;
        margin-top: 8px;
        @include t_16;
    }

    &__btn {
        margin-top: 16px;
        background: transparent;
    }
}

.skeleton {
    padding-top: calc($content-padding-top-desktop + 32px);

    @include small-desktop {
        padding-top: calc($content-padding-top + 16px);
    }

    &__middle {
        @include small-desktop {
            grid-row: 2;
            grid-column: 1/-1;
        }
    }

    &__double {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
        &_hr {
            width: 100%;
            height: 1px;
            background: #f3f3f3;;
        }
    }

    &__hr {
        border-radius: 8px;
        margin: 0 auto;
        margin-top: 30px;
        height: 4px;

        @include small-desktop {
            margin-top: 10px;
        }
    }

    &__wrapper {
        display: grid;
        grid-template-columns: 1fr 5fr;
        gap: 16px;

        @include small-desktop {
            display: block;
        }

        @include tablet {
            margin-top: 16px;

        }
    }

    &__left {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
    }

    &__rightDouble {
        padding-left: 24px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        position: relative;

        @include small-desktop {
            padding-left: 0;
        }

        &::before {
            content: '';
            position: absolute;
            background-color: #EBEBEB;
            height: 320px;            width: 1px;
            left: 0;
            top: -24px;

            @include small-desktop {
                width: calc(100% + 48px);
                height: 1px;
                top: -12px;
                left: -24px;
            }
        }

        span {
            &:nth-child(4) {
                margin-top: auto;
            }
        }
    }

    &__right {
        padding-left: 24px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        position: relative;

        @include small-desktop {
            padding-left: 0;
        }

        &::before {
            content: '';
            position: absolute;
            background-color: #EBEBEB;
            height: 176px;
            width: 1px;
            left: 0;
            top: -24px;

            @include small-desktop {
                width: calc(100% + 48px);
                height: 1px;
                top: -12px;
                left: -24px;
            }
        }

        &_item1 {
            height: 30px;
            width: 102px !important;
            position: relative;

        }

        &_item2 {
            width: 200px !important;
            height: 41px;

            @include small-desktop {
                width: 100% !important;
            }

        }

        &_item3 {
            width: 139px !important;
            height: 14px;
        }

        &_item4 {
            height: 17px;
            width: 137px !important;
        }
    }


    &__aside {
        display: flex;
        flex-direction: column;
        gap: 16px;

        @include small-desktop {
            display: none;
        }

        &_item {
            width: 326px;
            height: 208px;
            border-radius: 8px;
        }

        &_itemSmall {
            width: 326px;
            height: 41px;
        }

        &_itemBig {
            width: 326px;
            height: 384px;
        }
    }

    &__main {
        display: flex;
        flex-direction: column;
        gap: 16px;

        &_item {
            padding: 24px 24px;
            filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1));
            border-radius: 24px;
            background: $bg-primary;
            display: flex;
            gap: 24px;

            @include small-desktop {
                flex-direction: column;
            }
        }
    }

    &__item {
        &_header {
            display: flex;
            gap: 8px;
        }

        &_body {
            display: grid;
            grid-template-columns: 3fr 3fr 3fr 1fr;
            gap: 12px;
            margin-top: auto;

            @include small-desktop {
                grid-template-columns: 1fr 1fr;
            }
        }

        &_body1 {
            max-width: 100%;
            height: 55px;
        }

        &_body2 {
            max-width: 100%;
            height: 55px;
        }

        &_body3 {
            max-width: 100%;
            height: 40px;

            @include small-desktop {
                max-width: 120px;
            }
        }
    }
}

.isMobile {
    display: none;

    @include tablet {
        display: block;
    }
}

.hidden_ticket{
    display: none;
}
