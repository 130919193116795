@import '../../../scss/global.scss';

.how {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 64px;
    margin-top: 64px;

    @include tablet {
        gap: 16px;
        margin-bottom: 40px;
        margin-top: 40px;
    }

    &__title {
        color: $text-primary;
        @include t_48;
    }

    &__content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;

        @include tablet {
            grid-template-columns: 1fr;
        }

        &_item {
            background-color: $bg-additional;
            border-radius: 24px;
            padding: 32px 32px 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            min-height: 540px;

            @include tablet {
                min-height: 330px;
                padding: 20px 20px 0;
            }

            &:nth-child(1) {
                img {
                    margin-bottom: 23px;

                    @include tablet {
                        margin-bottom: 15px;
                        max-height: 160px;
                    }
                }
            }

            &:nth-child(2) {
                img {
                    margin-bottom: 60px;
                    @include tablet {
                        max-width: 340px;
                    }
                }
            }
            &:nth-child(3) {
                img {
                    @include tablet {
                        max-width: 240px
                    }
                }
            }
            &:nth-child(4) {
                img {
                    @include tablet {
                        max-width: 220px
                    }
                }
            }
            &:nth-child(5) {
                img {
                    @include tablet {
                        max-width: 320px
                    }

                    @include mobile {
                        max-width: 200px;
                    }
                }
            }

            &:nth-child(6) {
                background-color: $bg;
                position: relative;

                .how__content_text {
                    color: $text-secondary;
                }

                img {
                    margin-left: auto;
                    margin-right: 32px;
                    position: absolute;
                    max-width: 290px;
                    bottom: 0;
                    right: 0;

                    @include small-desktop {
                        max-width: 200px;
                    }

                    @include tablet {
                        max-width: 160px;
                        margin-left: auto;
                        margin-right: 20px;
                    }

                    @include mobile {
                        max-width: 150px;
                    }
                }

            }


        }

        &_title {
            color: $text-secondary;
            @include t_32;
        }

        &_text {
            color: $text-secondary2;
            @include t_16;
        }

        &_btn {
            margin-top: 16px;

            @include tablet {
                width: 100%;
                margin-top: 0;
                margin-bottom: 170px;
            }

            @include mobile {
                margin-bottom: 150px;
            }
        }

        &_counter {
            padding: 8px;
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            @include t_16;
            font-weight: 700 !important;
            background-color: $bg-primary;
        }

        img {
            margin: 0 auto;
            margin-top: auto;
            max-width: 100%;
        }
    }
}
