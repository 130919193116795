@import "../../../../scss/global";

.requisites_form {
    flex: 1;
    margin-top: 16px;

    &__wrapper {
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        background: $bg-primary;
        padding: 32px;
        position: relative;
        margin-top: 16px;
    }

    h1 {
        @include t_32;
        margin-bottom: 24px;
    }

    @include tablet {
        padding: 16px;
    }

    &__row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;

        @include tablet {
            display: block;
        }

        &__half_width_element {
            width: 49%;

            @include tablet {
                width: 100%;
                margin-bottom: 16px;
            }
        }

        &__full_width_element {
            width: 100%;
        }
    }
    &__buttons {
        display: flex;
        margin-top: 24px;

        @include tablet {
            display: block;
        }

        &__left {
            margin-right: 16px;
        }

        &__button {
            @include tablet {
                width: 100%;
                margin-bottom: 12px;
            }
        }
    }

    &__warning {
        display: flex;
        margin-top: 9px;
        color: $text-error;
    }

    &_title {
        display: flex;
        @include t_16;
        font-weight: 700;
        margin-top: 16px;
        margin-bottom: 16px;
        svg {
            margin-right: 9px;
        }
    }
}
