@import '../../scss/global.scss';

.policy {
    &__title {
        color: $text-primary;
        @include t_48;
        margin-top: 40px;
        word-wrap: break-word;

        @include tablet {
            margin-top: 32px;
        }

    }

    &__item {
        display: flex;
        flex-direction: column;
        gap: 12px;

        &_title {
            color: $text-primary;
            @include t_24;
            margin-top: 32px;

            @include tablet {
                margin-top: 16px;
            }
        }

        &_text {
            color: $text-primary;
            @include t_16;
        }
    }

    &__divider {
        width: 100%;
        height: 1px;
        background-color: $btn-addetional-hover;
        margin-top: 20px;

        @include tablet {
            margin-top: 4px;
        }

    }
}
