.passenger {
    position: relative;
    background-color: #F5F5F5;
    padding: 12px;
    border-radius: 12px;
    display: inline-flex;
    border: 1px solid #F5F5F5;
    gap: 8px;
    margin: 4px;
    cursor: pointer;
    &__active {
        background-color: #F7F8FA;
        position: relative;
        padding: 12px;
        border-radius: 12px;
        display: inline-flex;
        gap: 8px;
        margin: 4px;
        cursor: pointer;
        border: 1px solid #ED7E2E;        
    }
    &__ring {
        position: relative;
        width: 24px;
        height: 24px;
        border-radius: 12px;
        border: 1px solid #CFCFCF;
        background-color: #fff;
        &__active {
            position: relative;
            width: 24px;
            height: 24px;
            border-radius: 12px;
            border: 1px solid #ED7E2E;
            background-color: #ED7E2E;
        }
        &__active::before {
            position: absolute;
            left: -1px;
            top: 40%;
            height: 38%;
            width: 3px;
            background-color: #fff;
            content: "";
            transform: translateX(10px) rotate(-45deg);
            transform-origin: left bottom;
            border-radius:4px
        }
        &__active::after {
            position: absolute;
            width: 18px;
            height: 18px;
            left: -1px;
            bottom: 5px;
            height: 3px;
            width: 67%;
            background-color: #fff;
            content: "";
            transform: translateX(10px) rotate(-45deg);
            transform-origin: left bottom;
            border-radius:4px
        }
    }
    &__name {
        color: #000;
        font-size: 14px;
        line-height: 16.8px;
        width: max-content;
    }
    &__birthday {
        color: #8A8A8A;
    }
}
.passenger2 {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 16px;
    overflow-x: auto;
}