@import '../../../scss/global.scss';

.coop {
    background-color: $bg-secondary;

    padding: 64px 0;

    @include tablet {
        padding: 0 0 40px 0;
    }

    &__title {
        @include t_48;
        span {
            color: $text-actions;
        }
    }

    &__items {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        grid-gap: 16px;
        margin-top: 32px;

        @include tablet {
            margin-top: 24px;
        }

        @include mobile {
        }
    }

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;

        &_img {
            max-width: 100%;
        }
    }
}