@import '../../../scss/global.scss';

.profile_menu{

    &__userImg {
        margin-top: 16px;
    }

  &_header{
    display: none;
    height: 56px;

    svg{
      transform: rotate(45deg);
      position: absolute;
      left: 16px;
      top: 16px;
      cursor: pointer;
    }

    @include small-desktop{
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 16px;
    }
  }

  @include small-desktop{
    position: absolute;
    left: 0;
    right: 0;
    height: 100vh;
    background: #fff;
    padding-left: 24px;
  }

  @include tablet{
    padding-left: 16px;

  }

  &_username{
    @include t_20;
    margin-top: 8px;
    margin-bottom: 4px;
  }

  &_email{
    @include t_14
  }

  &_items{
    margin-top: 30px;

    a{
      @include t_14;
      color: $text-primary;
      cursor: pointer;
    }

    &_item{
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      padding: 16px;
      height: 48px;

      svg{
        margin-right: 10px;
      }

      &_active{
        background: $bg-primary2;
        border-radius: 16px;

        @include small-desktop{
          background: transparent;
        }

        a{
          color: $text-secondary;
          font-weight: 700;

          @include small-desktop{
            color: $text-primary;
            font-weight: 400;
          }
        }

        path{
          fill: $bg;

          @include small-desktop{
            fill: $icon-secondary;
          }
        }
      }
    }

    &_partner{
      color: $text-secondary2;
      @include t_12;
      margin-bottom: 8px;
      margin-top: 16px;
    }
  }

  &_dropdown{
    position: absolute;
    right: 0;
    background: $bg-primary;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding-left: 8px;
    padding-top: 16px;
    top: 61px;

    .profile_menu_username{
      font-size: 16px;
      text-transform: uppercase;
      margin-left: 16px;
    }

    .profile_menu_email{
      margin-left: 16px;
    }
  }
}


.burger {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 24px;
    height: 24px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
    left: 16px;
    position: absolute;
    top: 16px;

    &:focus {
        outline: none;
    }

    div {
        width: 24px;
        height: 1.7px;
        border-radius: 10px;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 1px;
        background: $bg;
    }
}