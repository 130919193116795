@import '../../scss/global.scss';

.profile_page {
    padding-top: calc($content-padding-top-desktop + 32px);

    @include small-desktop {
        padding-top: calc($content-padding-top + 32px);
    }

    background-color: $bg-secondary;

    &__wrapper {
        display: flex;
        gap: 16px;
    }

    &_menu {
        width: 326px;
        margin-right: 16px;

        @include small-desktop {
            display: none;
        }
    }

    &_header {
        position: relative;
        background: $bg-primary2;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 40px;

        &_icon{
            width: 96px;
            height: 96px;

            @include tablet {
                width: 72px;
                height: 72px;
            }
        }


        @include tablet {
            display: block;
            padding: 20px 16px;
            text-align: right;

            &_icon {
                text-align: right;
                margin-top: 20px;
            }
        }


        &_title {
            color: $text-secondary;
            @include t_32;

            p {
                margin-bottom: 8px;
            }

            &_description {
                @include t_14;
                margin-bottom: 16px;
            }

            @include tablet {
                text-align: left;
            }
        }

        &_buttons {
            display: flex;

            @include mobile {
                display: block;
            }

            &_button {
                margin-right: 8px;

                @include tablet {
                    width: 100%;
                }
            }
        }

        p{
            color: #fff;
        }
    }
}
