@import '../../../../scss/global.scss';

.transfer {
    padding: 5px 13px;
    border-radius: 24px;
    background: $bg-secondary;
    display: flex;
    gap: 8px;
    width: fit-content;
    align-items: center;
    span {
        color: $text-secondary2;
        @include t_14;
    }
}