@import '../../../scss/global.scss';

.about {
    background: $bg-primary;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include tablet {
        padding: 16px;
    }

    &__title {
        color: $text-primary;
        @include t_24;
        font-weight: 700 !important;
    }

    &__content {
        display: grid;
        justify-content: space-between;
        max-width: 90%;
        grid-template-columns: repeat(4,160px);

        @include tablet {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 12px;
            max-width: 100%;

        }

        &_item {
            display: flex;
            flex-direction: column;
            gap: 8px;
            color: $text-primary;
        }

        &_text {
            color: $text-primary;
            @include t_14;
            font-weight: 700 !important;
        }

        &_info {
            margin-top: -6px;
        }
        p {
            font-size: 14px;
            line-height: 17px;
            font-weight: 700 !important;
            a {
                color: #ed7e2e;
            }
        }
    }
}