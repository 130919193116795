@import "../../scss/global.scss";

.header {
    padding: 16px 0 0;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    background: $bg-primary;
    border-radius: 0px 0px 16px 16px;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    @include small-desktop {
        padding: 0;
    }
}

.upheader {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    border-bottom: 1px solid #e1e1e1;

    @include small-desktop {
        border: 0;
    }

    &__menu {
        margin-left: auto;

        &_elem {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                height: 1px;
                background-color: $btn-addetional-hover;
                width: 100%;
                bottom: -16px;
            }
        }

        &_item {
            color: $text-primary;
            @include t_14;

            &:hover {
                color: $text-actions;
            }
        }
    }

    &__profile {
        margin-left: auto;

        &_btn {
            cursor: pointer;
        }
    }

    &__mobile {
        padding-top: 16px;
        display: none;
        grid-template-columns: 10fr 1fr 1fr 1fr 1fr;
        background: $bg-primary;
        align-items: center;

        @include small-desktop {
            display: grid !important;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                z-index: -1;
                height: 61px;
                box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
                border-radius: 0px 0px 16px 16px;
            }
        }

        @include tablet {
            gap: 25px;
        }
    }

    &__desktop {
        gap: 12px;

        @include small-desktop {
            display: none !important;
        }
    }

    &__btns {
        display: flex;
        gap: 24px;
    }

    &__logo {
        text-transform: uppercase;
        display: flex;
        align-items: center;
        width: min-content;

        &_img {
            width: 36px;
            height: 30px;
            margin-right: 8.5px;
            background: url(../../assets/icons/logo.svg);
            background-repeat: no-repeat;
            background-size: contain;
        }

        &_text {
            background: url(../../assets/icons/AirSurfer.svg);
            background-position: center;
            background-repeat: no-repeat;
            width: 108.78px;
            height: 18.13px;
        }
    }
}

.upheader__btns {
    display: flex;
}

.button {
    background-color: $bg-primary;
    background: unset;
    display: flex;
    align-items: center;
    font-weight: 700 !important;
    @include t_14;
    color: $text-primary;

    &:hover {
        border-bottom: 2px solid #e2e2e2;

        @include small-desktop {
            border-bottom: none;
        }
    }

    &__img_plane {
        width: 15px;
        height: 13px;
        margin-right: 2.5px;
        background: url(../../assets/icons/plane-nav.svg);
    }

    &__img_heart {
        width: 15.5px;
        height: 12.8px;
        margin-right: 4.8px;
        background: url(../../assets/icons/heart-nav.svg);
    }

    &__img_doc {
        width: 14px;
        height: 17px;
        margin-right: 5.8px;
        background: url(../../assets/icons/doc-nav.svg);
    }

    &__active {
        background-color: $bg-primary;
        display: flex;
        align-items: center;
        position: relative;
        font-weight: 700 !important;
        @include t_14;
        color: $text-primary;

        &::after {
            content: "";
            width: 100%;
            height: 2px;
            top: 44px;
            position: absolute;
            background-color: $btn-primary;

            @include small-desktop {
                display: none;
            }
        }

        &_mobile {
            border: 0;
        }

        &__img_plane {
            width: 15px;
            height: 13px;
            margin-right: 2.5px;
            background: url(../../assets/icons/plane-nav-active.svg);
        }
        &__img_doc {
            width: 14px;
            height: 17px;
            margin-right: 5.8px;
            background: url(../../assets/icons/doc-nav-active.svg);
        }
    }
    &__currency {
        margin-left: auto;
        color: $text-actions;
        font-weight: 600;
        background-color: $bg-primary;
        &:hover {
            color: $text-actions-hover;
        }
    }
    &__signin {
        display: flex;
        align-items: center;
        font-weight: 600;
        color: $text-actions;
        padding: 8px 16px;
        border: 1px solid $text-actions;
        border-radius: 12px;
        background-color: $bg-primary;

        &_icon {
            width: 24px;
            height: 24px;
            margin-right: 4px;
            background: url(../../assets/icons/signin.svg);
        }

        &:hover {
            color: $text-actions-hover;
            border: 1px solid $text-actions-hover;
        }

        &:hover .button__signin_icon {
            background: url(../../assets/icons/signin-active.svg);
        }
    }
    &__link {
        background-color: $bg-primary;
        display: flex;
        align-items: center;
        font-weight: 700;
        color: $text-primary;

        &:hover {

        }

        &_info {
            margin-left: 4px;
            width: 47px;
            height: 20px;
            background: #0094ff;
            border-radius: 24px;
            font-size: 10px;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    &__disabled {
        background-color: $bg-primary;
        display: flex;
        align-items: center;
        font-weight: 700;
        color: $text-primary;

        &:hover {
            cursor: not-allowed;
        }

        &_info {
            margin-left: 4px;
            width: 47px;
            height: 20px;
            background: #0094ff;
            border-radius: 24px;
            font-size: 10px;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.menu {
    &__block {
        margin-top: 4px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        a {
            color: $text-secondary2;
            @include t_14;
        }

        svg {
            margin-top: 4px;
        }

        &_link {
            display: flex;
            align-items: center;
            gap: 4px;

            button {
                height: auto;
                padding: 8px 0;
            }
        }
    }
}

.upheader__links {
    display: flex;
    align-items: center;
    gap: 24px;
}

.link2 {
    color: $text-secondary2;
    font-weight: 400;

    &:hover {
        color: $text-actions;
    }
}

.link1 {
    color: $text-primary;
    font-weight: 400;

    &:hover {
        color: $text-actions;
    }
}

.downheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &__nav {
        display: flex;
        align-items: center;
        gap: 24px;
        padding: 23.5px 0;
    }
}

.dropdown {
    @include t_14;

    &__container {
        gap: 12px;
    }
}
