@import 'scss/global.scss';

.page {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 21px;
}

.form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    gap: 30px;
    background: $bg-primary;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 32px;

    @include tablet {
        padding: 16px;
        gap: 20px;
    }
}

.note{
    color: $text-secondary2;
}

.error{
    color: $text-error;
}

.btn{
    justify-content: center;
}