.plus_minus{
  justify-content: space-between;
  width: 108px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #CFCFCF;
  border-radius: 24px;
  display: flex;
  align-items: center;
  padding: 8px 12px;

  .minus {
    svg{
      margin-bottom: 4px;
      path{
        stroke: #000;
      }
    }
  }

  .plus {
    svg{
      margin-top: 5px;
    }
  }

  span{
    font-weight: 700;
    font-size: 14px;
  }
}