@import "scss/global.scss";

.dropdown_input {
    width: 100%;
    height: 62px;

    &__option {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        svg {
            // min-width: 30px;
            // max-width: 30px;
            // height: 12px;
            // margin-right: 5px;
        }
        &__label {
            word-break: break-word;
            white-space: break-spaces;
        }
        &__value {
            color: $text-secondary2;
            @include t_14
        }
    }

    :global {
        .ant-select-selector {
            height: 62px !important;
            border: 1px solid $bg-secondary2 !important;
            border-radius: 8px !important;
            padding-left: 16px !important;

            .ant-select-selection-item {
                padding: 21px 0;
                @include t_16;
            }
        }

        .ant-select-selection-search-input {
            height: 60px !important;
            padding-top: 20px !important;
            padding-left: 4px !important;
        }
    }

    &_with_label {
        :global {
            .ant-select-selector {
                .ant-select-selection-item {
                    padding: 12px 0;
                    line-height: 60px;
                    height: 100%;
                }
            }
        }
    }

    &_error {
        :global {
            .ant-select-selector {
                border: 1px solid $text-error !important;
            }
        }
    }
}

.dropdown_item_container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .dropdown_input_label {
        position: absolute;
        top: 12px;
        left: 16px;
        color: $text-secondary2;
    }
}

.error_text {
    position: absolute;
    bottom: -15px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #9b0202;
    margin-top: 2px;
    z-index: 2;
}


.remove {
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background: none !important; // Отключаем фон
    }
}


.flag{
    height: 10px;
}

.selectedValue{
    position: relative;
    display: flex;
    height: 100%;
    align-items: flex-end;
}

.selectedContent{
    display: flex;
    align-items: center;
    gap: 5px;
}
