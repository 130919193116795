@import '../../../scss/global.scss';

.crumb {
    display: flex;
    gap: 9px;
    cursor: pointer;
    width: fit-content;
    color: $text-actions;
    padding-left: 5px;


    &__title {
        @include t_14;
        color: $text-actions;
    }
}
