@import '../../../scss/global.scss';

.links_page {
  flex: 1;

  &_sort_button{
    display: none;
    width: 100%;
    margin-bottom: 32px;
    margin-top: 16px;

    @include small-desktop{
      display: block;
    }
  }

  &_table{
    @include small-desktop{
      display: none;
    }
  }

  &_list{
    display: none;

    @include small-desktop{
      display: block;
    }
  }

  &_name{
    @include t_14;
    font-weight: 700;
    color: $text-primary;
  }

  &_link{
    @include t_12;
    color: $text-secondary2;
    margin-top: 4px;
  }

  .link{
    background: $bg-primary;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    padding: 24px;
    margin-top: 16px;

    @include tablet{
        padding: 16px;
    }

    &_header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 16px;
    }

    &_name{
      color: #000;
      @include t_20;
      margin-bottom: 4px;
    }

    &_link{
      color: $text-secondary2;
      @include t_14;
    }

    &_info{
      display: flex;
      padding-top: 16px;

      @include tablet{
        flex-wrap: wrap;
      }

      &_item{
        width: 48%;

        @include tablet{
          margin-bottom: 16px;

          &:nth-last-child(-n + 2){
              margin-bottom: 0;
          }
        }

        &_title{
          @include t_12;
          margin-bottom: 4px;
          color: $text-secondary2;
        }

        &_value{
          @include t_14;
          font-weight: 700;

          @include tablet{
            font-weight: 700;
          }
        }
      }
    }
  }

  &_sorting{
    display: none;

    &_visible{
      position: absolute;
      top: 96px;
      bottom: 0;
      width: 100%;
      background: #fff;
      padding-right: 24px;

      @include small-desktop{
        display: block;
      }
    }
  }
  &_show_more{
    width: 100%;
    margin-top: 16px;
    background-color: transparent;
  }
}

.link_page{
  flex: 1;
  position: relative;

  &_empty{
    margin-top: 56px;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 16px;
  }

  &_breadcrumb{
    position: absolute;
    top: 0;
    left: 0;
  }
}

.link_form {
  flex: 1;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  background: $bg-primary;
  padding: 32px;
  position: relative;
  margin-top: 35px;

  &_breadcrumb{
      position: absolute;
      top: -32px;
      left: 0;
  }


  h1{
      @include t_32;
      margin-bottom: 24px;
  }

  @include tablet{
      padding: 16px;
  }

  &_row{
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    @include tablet{
      display: block;
    }

    &_element{
      width: 49%;

      @include tablet{
        width: 100%;
        margin-bottom: 16px;
      }
    }

    &_full_width_element{
      width: 100%;
    }
  }

  &_slider_container{
    width: 49%;
    background: $bg-secondary;
    padding: 16px;
    border-radius: 16px;

    @include tablet{
      width: 100%;
    }

    &_title{
      display: flex;
      margin-bottom: 16px;

      p{
        @include t_16;
        font-weight: 700;
      }

      svg{
        margin-right: 12px;
      }
    }

    &_description{
      display: flex;
      justify-content: space-between;

      &_col{
        width: 45%;


        &_right{
          text-align: right;
        }

        &_title{
          @include t_14;
          font-weight: 700;
        }

        &_text{
          @include t_12;
          color:$text-secondary2
        }
      }

    }

    &_values{
      display: flex;
      justify-content: space-between;

      &_value{
        @include t_20;
      }
    }

    &_slider{
      width: 100%;
    }
  }

  &_buttons{
    display: flex;
    margin-top: 24px;

    @include tablet{
      display: block;
    }

    &_left{
      margin-right: 16px;
    }

    &_button{
      @include tablet{
        width: 100%;
        margin-bottom: 12px;
      }
    }
  }

  &_warning{
    display: flex;
    margin-top: 9px;
    color: $text-error;
  }
}

.order {
  background: $bg-primary;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  padding: 24px;
  margin-top: 16px;

  @include small-desktop{
    padding: 0 24px;
  }

  &_info{
    display: flex;
    padding-top: 16px;

    @include small-desktop{
      flex-wrap: wrap;
    }

    &_item{
      width: 50%;

      @include small-desktop{
        margin-bottom: 16px;
      }

      &_title{
        @include t_12;
        margin-bottom: 4px;
        color: $text-secondary2;
      }

      &_value{
        @include t_14;
        font-weight: 700;

        @include tablet{
          font-weight: 700;
        }
      }
    }
  }
}

.header {
    position: relative;
    background: $bg-primary2;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px;
    margin-top: 35px;


    &_discounts{
      display: flex;

      &>div{
        width: 50%;
        margin-bottom: 16px;
      }
    }

    &_link{
        width: 100%;

        &_info{
            display: flex;
            width: 100%;
            margin-bottom: 16px;

            & > div{
                width: 50%;

                @include tablet{
                  width: 100%;
                }
            }

            @include tablet{
              flex-direction: column;
            }
        }
    }

    &_name{
        color: #8A8A8A;
        font-size: 12px;
        margin-bottom: 4px;
    }

    &_icon{
        width: 96px;
        height: 96px;

        @include tablet {
            width: 72px;
            height: 72px;
        }
    }


    @include tablet {
        display: block;
        padding: 20px 16px;

        &_icon {
            text-align: right;
            margin-top: 20px;
        }
    }


    &_title {
        color: $text-secondary;
        @include t_32;
        margin-bottom: 16px;
    }

    &_description {
        color: $text-secondary;
        @include t_14;
        margin-bottom: 4px;
    }


    &_button {
        margin-bottom: 16px;

        @include tablet {
          width: 100%;
        }
    }
}

.spinner{
  margin: auto;
}

