@import '../../../scss/global.scss';

.bookings {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
    margin-bottom: 20px;

    &__moreBtn {
        height: auto;
        padding: 12px 0;
        background-color: transparent;
    }

    &__block {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__divider {
        width: 100%;
        background-color: $btn-addetional-hover;
        height: 1px;
        margin: 32px 0;

        @include tablet {
            margin: 16px 0;
        }
    }

    &__dropdown {
        width: 150px;
    }

    &__header {
        padding: 40px;
        background-color: $bg-primary2;
        border-radius: 16px;
        position: relative;

        @include tablet {
            padding: 20px 16px 60px
        }


        &_title {
            @include t_32;
            color: $text-secondary;

        }

        &_img {
            position: absolute;
            right: 24px;
            top: 50%;
            transform: translateY(-50%);
            max-width: 96px;

            @include tablet {
                width: 10%;
                bottom: 0;
                right: 0;
                top: auto;
                transform: translateY(0);
            }

            @include mobile {
                width: 20%;
            }
        }
    }
}