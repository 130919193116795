@import "../../../scss/global.scss";

.settings {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
    padding-bottom: 4px;

    &__divider {
        width: 100%;
        background-color: $btn-addetional-hover;
        height: 1px;
        margin: 32px 0;

        @include tablet {
            margin: 16px 0;
        }
    }

    &__dropdown {
        width: 150px;
    }

    &__header {
        padding: 40px;
        background-color: $bg-primary2;
        border-radius: 16px;
        position: relative;

        @include tablet {
            padding: 20px 16px 60px;
        }

        &_title {
            @include t_32;
            color: $text-secondary;
        }

        &_img {
            position: absolute;
            right: 24px;
            top: 50%;
            transform: translateY(-50%);

            @include tablet {
                width: 10%;
                bottom: 0;
                right: 0;
                top: auto;
                transform: translateY(0);
            }

            @include mobile {
                width: 20%;
            }
        }
    }

    &__block {
        padding: 32px;
        background: $bg-primary;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
        border-radius: 16px;

        @include tablet {
            padding: 16px;
        }
    }

    &__item {
        display: flex;
        gap: 16px;
        flex-direction: column;

        &_info {
            margin-bottom: 8px;
            color: $text-secondary2;
            @include t_14;
        }

        &_inputs2 {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 16px;

            @include tablet {
                grid-template-columns: 1fr;
                grid-auto-rows: 1fr;
                grid-template-rows: 1fr;
            }

            &_password {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 16px;

                @include tablet {
                    grid-template-columns: 1fr;
                    grid-auto-rows: auto;
                    grid-template-rows: 1fr;
                }
            }
        }

        &_inputs3 {
            display: grid;
            grid-template-columns: calc(50% - 8px) 130px auto;
            gap: 16px;

            @include tablet {
                grid-template-columns: 120px 4fr;

                div {
                    &:nth-child(1) {
                        grid-column: 1 / -1;
                    }

                    &:nth-child(2) {
                        grid-row: 2;
                    }

                    &:nth-child(3) {
                        grid-column: 2 / -1;
                    }
                }
            }
        }

        &_header {
            display: flex;
            gap: 10px;
            align-items: center;
        }

        &_title {
            @include t_16;
            font-weight: 700 !important;
            color: $text-primary;
        }
    }

    &__btn {
        margin-top: 28px;
        border: none;
        width: 205px;
        @include t_16;
        font-weight: 700 !important;
        border-radius: 12px;

        @include tablet {
            margin-top: 0;
        }

        @include mobile {
            width: 100%;
        }
    }

    &__email_not_verified {
        color: #8a8a8a;
        font-size: 12px;
        margin: 8px 4px;

        img {
            margin-top: -4px;
            margin-right: 4px;
        }
    }

    &__verify_email {
        color: #ed7e2e;
        font-size: 14px;
        margin-top: 8px;
        text-decoration: underline;
        cursor: pointer;
        position: relative;
        display: flex;
    }

    &__spinner {
        position: absolute;
        left: 16px;

        &_email {
            position: absolute;
            left: 82px;
            right: 0;
            top: -20px;
        }
    }
}

.customer {
    &__inputs {
        margin-top: 16px;
        display: grid;
        grid-template-columns: 4fr 1fr 3fr;
        gap: 16px;

        @include tablet {
            grid-template-columns: 1fr 1fr 10fr;
            gap: 16px;

            div {
                &:nth-child(1) {
                    grid-column: 1 / -1;
                }

                &:nth-child(2) {
                    width: fit-content;
                    grid-row: 2;
                }

                &:nth-child(3) {
                    grid-column: 2 / -1;
                }
            }
        }
    }
}

.form {
    &__field {
        &_checkbox {
            display: flex;
            gap: 4px;
            align-items: center;
            color: $text-primary;
            @include t_14;
            user-select: none;

            @include tablet {
                margin-top: -20px;
            }

            @include mobile {
                margin-top: -24px;
            }
        }
    }
}

.error_text {
    @include t_12;
    color: $text-error;
    margin-top: 2px;
}
