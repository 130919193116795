@import "../../../scss/global.scss";

.custom_table{
  :global{
    .ant-table{
      background: $bg-secondary;
      border-radius: 24px 24px 0 0;

      table{
        border-spacing: 0 8px;

        tr{
          border-radius: 24px;
          background: $bg-primary;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

          td:last-child, th:last-child{
            border-radius: 0 24px 24px 0;
          }

          td:first-child, th:first-child{
            border-radius: 24px 0 0 24px;
          }

          th{
            background: $bg-primary;
            @include t_14;
            color: $text-secondary2;

            &:before{
              display: none;
            }
          }
          th[aria-sort="descending"], th[aria-sort="ascending"]{
              color: #c96b27;
          }
        }

        .anticon-caret-up{
          display: none;
        }

        .anticon-caret-down{
          position: absolute;
          top: 0;
          bottom: 0;
          height: 12px;
          margin: auto !important;
        }

        .ant-table-column-sort{
          .anticon{
            display: none;
          }
          .active{
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            height: 12px;
            margin: auto;

            svg{
              fill: $bg;
            }
          }
        }
      }
      .ant-table-column-sorters{
          justify-content: start;

          span{
              flex: none;
              margin-left: 4px;
          }
      }
    }
    .ant-table-column-sorters{
        justify-content: start;

        .ant-table-column-title{
            flex: none;
            margin-right: 7px;
        }
    }

    .ant-table-cell[aria-sort="ascending"], .ant-table-cell[aria-sort="descending"]{
          color: #c96b27;
    }
  }
}
