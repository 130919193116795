@import 'scss/global.scss';

.reliably {
    background-color: $bg-secondary;

    padding: 64px 0;

    @include tablet {
        padding: 40px 0;
    }

    &__title {
        @include t_48;

        span {
            color: $text-actions;
        }
    }

    &__items {
        margin-top: 32px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        grid-gap: 16px;

        @include small-desktop {
            grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
            margin-top: 24px;

        }

        @include tablet {
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        }
    }

    &__item {
        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        background: $bg-primary;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
        border-radius: 24px;

        @include tablet {
            padding: 16px;
        }

        &_link {
            color: $text-actions;
        }

        &_title {
            color: $text-primary;
            @include t_24;
        }

        &_img {
            max-width: 40px;
        }

        &_text {
            color: $text-secondary2;
            @include t_14;
        }
    }
}

.colored{
    color: $bg;
}