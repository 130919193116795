@import '../../../scss/global.scss';

.route {
    background: $bg-primary;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 32px;

    @include tablet {
        padding: 16px;
    }

    &__dateInput {
        max-width: calc(50% - 8px);
    }

    &__title {
        color: $text-primary;
        @include t_24;
    }

    &__cards {
        display: flex;
        gap: 16px;
        margin-top: 16px;
        flex-wrap: wrap;
    }

    &__date {
        margin-top: 16px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        border-radius: 16px;
        background: $bg-secondary;

        @include tablet {
            padding: 16px;
        }

        &_datepicker {
            max-width: calc(50% - 16px);
            width: 100%;
            margin-top: 12px;
            @include tablet {
                max-width: 100%;
            }
        }

        &_title {
            color: $text-primary;
            @include t_16;
            font-weight: 700 !important;

            @include tablet {
                font-weight: 700!important;
                font-size: 16px;
                line-height: 19px;
            }
        }

        &_header {
            display: flex;
            gap: 8px;
            align-items: center;
        }
    }

}

