@import 'scss/global.scss';

.page {
    display: flex;
    align-items: flex-start;
    width: 100%;
    gap: 21px;
}

.form {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;
    background: $bg-primary;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 32px;

    @include tablet {
        padding: 16px;
        gap: 20px;
    }

}

.card {
    width: calc(50% - 10px);

    @include tablet {
        width: 100%;
    }
}