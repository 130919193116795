@import '../../../scss/global.scss';

.about {
    display: flex;
    flex-direction: column;
    gap: 32px;

    margin-top: 64px;

    @include tablet {
        gap: 24px;
        margin-top: 40px;
    }

    &__title {
        @include t_48;
        color: $text-primary;
    }

    &__content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;

        @include tablet {
            grid-template-columns: 1fr;

        }

        &_item {
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
            border-radius: 24px;
            background-color: $bg-primary;
            padding: 32px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;

            @include tablet {
                padding: 16px;
            }

            img {
                width: 40px;
                height: 40px;
            }

        }

        &_title {
            color: $text-primary;
            @include t_24;
            font-weight: 700 !important;
        }

        &_text {
            color: $text-secondary2;
            @include t_14;
        }
    }

}
