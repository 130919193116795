@import '../../../../scss/global.scss';

.passengers {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background: $bg-primary;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 32px;

    @include tablet {
        padding: 16px;
        gap: 12px;

    }

    &__title {
        color: $text-primary;
        @include t_24;
    }
}