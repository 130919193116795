@import '../../../scss/global.scss';

.hotel {

    background: $bg-primary;
    border-radius: 16px;
    justify-content: space-between;
    padding: 32px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;

    &__list{
        display: flex;
        gap: 16px;
        margin-top: 16px;
        flex-wrap: wrap;
    }

    @include tablet {
        padding: 16px;
    }
    &__card {
        border: 2px solid $text-secondary3;
        background: $bg-primary;
        border: 2px solid #CFCFCF;
        border-radius: 16px;
        margin-top: 16px;
        width: calc(50% - 16px);

        @include tablet {
            width: 100%;
        }
    }

    &__title {
        color: $text-primary;
        @include t_24;
    }

    &__active {
        border-color: $bg;
    }

    &__header {
        padding: 16px 24px;
        background: $bg-secondary;
        border-radius: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include tablet {
            padding: 16px;
        }

        &_checkbox {
            display: flex;
            gap: 12px;
            margin-bottom: 18px;
            cursor: pointer;
            border-radius: 50%;
            background: $text-secondary;
            border: 1px solid $text-secondary3;
            width: 23px;
            height: 23px;

            input {
                appearance: none;
                width: 23px;
                height: 100%;
                border-radius: 50%;
                position: relative;
                cursor: pointer;

                &:checked {
                    background: $bg;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: none;

                    &::after {
                        content: url('../../../assets/icons/whiteCheckMark.svg');
                        position: absolute;
                    }
                }
            }
        }
    }


    &__main {
        padding: 16px 24px 24px;

        @include tablet {
            padding: 16px;
        }

        &_logo {
            color: $text-primary;
            @include t_16;
            font-weight: 700!important;
        }

        &_text {
            color: $text-primary;
            @include t_20;
            font-weight: 700!important;

            span {
                color: $text-secondary2;
                @include t_14
            }
        }
    }

}

.details {
    margin-top: 16px;
    padding: 24px;
    background: $bg-secondary;
    border-radius: 16px;

    &__header {
        display: flex;
        align-items: center;
        gap: 9px;
    }

    &__title {
        @include t_16;
        font-weight: 700 !important;
        color: $text-primary;

        @include tablet {
            font-size: 16px;
            line-height: 19px;
        }
    }

    &__inputs {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(2, auto);
        grid-gap: 16px;
        margin-top: 12px;




    }

    &__input {
        &:nth-child(1) {
            grid-column: 1 / -1;

            @include tablet {
                grid-column: 1 / -1;
            }
        }

        &:nth-child(2) {
            grid-row: 2 / 3;

            @include tablet {
                grid-column: 1 / -1;
                grid-row: 2;

            }
        }

        &:nth-child(3) {
            grid-row: 2 / 3;
            grid-column: 2 / 3;

            @include tablet {
                grid-column: 1 / -1;
                grid-row: 3;

            }
        }
    }
}
