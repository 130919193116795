@import '../../../scss/global.scss';

.reviews {
    background-color: $bg-secondary;
    padding: 64px 0;

    @include tablet {
        padding: 40px 0;

    }

    &__container {

        max-width: 1440px;
        margin: 0 auto;
        align-items: inherit;


        @include tablet {
            max-width: none;
            padding: 0;
            margin: 0;
            align-items: normal;
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
    }

    &__btn {
        padding: 18px 32px;
        color: $text-actions;
        border: 1px solid $text-actions;
        @include t_16;
        font-weight: 700 !important;
        border-radius: 12px;
        margin-top: 24px;
        background-color: $bg-secondary;
        width: fit-content;

        @include tablet {
            width: auto;
        }

    }

    &__items {
        margin-top: 40px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
        gap: 16px;

        @include small-desktop {
            margin-top: 32px;
        }

        @include tablet {
            display: flex;
            flex-wrap: nowrap;
            overflow-x: scroll;
            margin-top: 16px;
        }

    }

    &__item {
        padding: 32px;
        background-color: $bg-primary;
        border-radius: 24px;

        @include tablet {
            min-width: 340px;
        }

        &_title {
            color: $text-primary;
            @include t_20;

        }

        &_date {
            color: $text-secondary2;
            @include t_14;
            margin-top: 4px;
        }

        &_stars {
            margin-top: 17px;
        }

        &_text {
            color: $text-primary;
            @include t_18;
            margin-top: 18px;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        gap: 10px;


        @include tablet {
            flex-direction: column;
            align-items: flex-start;
        }

        &_text {
            display: flex;
            flex-direction: column;
            gap: 10px;
            color: $text-secondary;
        }

        &_title {
            @include t_48;
            color: $text-primary;

            @include small-desktop {
                width: 80%;

            }

            @include tablet {
                width: 100%;
            }
        }

        &_note {
            @include t_18;
            color: $text-primary;
        }

        &_info {
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding: 12px;
            background: $bg-primary;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
            border-radius: 16px;
            height: fit-content;
        }

        &_leftSide {
            display: flex;
            gap: 8px;
            @include t_24;
        }

        &_rightSide {
            display: flex;
            gap: 8px;
            justify-content: center;
            align-items: center;
            background: $bg-primary2;
            color: $text-secondary;
            border-radius: 24px;
            padding: 8px 12px;

        }
    }


}