@import 'scss/global.scss';

.body18 {
  font-size: 1.125rem; // 18px
  font-weight: normal;

  @include tablet {
    font-size: 1rem; // 16px
  }
}

// Стили для Body16
.body16 {
  font-size: 1rem; // 16px
  font-weight: normal;

  @include tablet {
    font-size: 0.875rem; // 14px
  }
}

// Стили для Body14
.body14 {
  font-size: 0.875rem; // 14px
  font-weight: normal;

  @include tablet {
    font-size: 0.75rem; // 12px
  }
}

// Стили для Body12
.body12 {
  font-size: 0.75rem; // 12px
  font-weight: normal;

  @include tablet {
    font-size: 0.75rem; // 12px (не изменяется)
  }
}

.bold{
  font-weight: 700;
}