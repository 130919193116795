@import 'scss/global.scss';

.form {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.title{
    display: flex;
    gap: 8px;
}

.titleText{
    font-weight: 700;
}

.inputs{
    display: flex;
    gap: 26px;
    flex-wrap: wrap;

    &>*{
        width: calc(50% - 13px);

        @include tablet{
            width: 100%;
        }
    }
}