@import "../../../scss/global.scss";

.dropdown_input {
    width: 100%;
    height: 62px;

    &__option {
        display: flex;
        align-items: center;
        width: 100%;
        svg {
            min-width: 30px;
            max-width: 30px;
            height: 25px;
            margin-right: 5px;
        }
        &__label {
            word-break: break-word;
            white-space: break-spaces;
        }
    }

    :global {
        .ant-select-selector {
            height: 62px !important;
            border: 1px solid $bg-secondary2 !important;
            border-radius: 8px !important;
            padding-left: 16px !important;

            .ant-select-selection-item {
                padding: 21px 0;
                @include t_16;
            }
        }

        .ant-select-selection-search-input {
            height: 60px !important;
            padding-top: 20px !important;
            padding-left: 4px !important;
        }
    }

    &_with_label {
        :global {
            .ant-select-selector {
                .ant-select-selection-item {
                    padding: 12px 0;
                    line-height: 60px;
                    height: 100%;
                }
            }
        }
    }

    &_error {
        :global {
            .ant-select-selector {
                border: 1px solid $text-error !important;
            }
        }
    }
}

:global {
    .ant-select-dropdown {
        .ant-select-item {
            font-size: 14px;

            &:hover {
                background-color: $bg-primary;
            }
        }

        .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
            color: $text-black;
            font-weight: 700;
            background: url("../../../assets/icons/check.png") no-repeat
                calc(100% - 16px);
            transition: unset;
        }
    }
}

.dropdown_item_container {
    position: relative;

    .dropdown_input_label {
        position: absolute;
        top: 12px;
        left: 16px;
        color: $text-secondary2;
    }
}

.error_text {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: $text-error;
    margin-top: 2px;
}
