@import 'scss/global.scss';

.form {
    display: flex;
    flex-grow: 1;
    width: 100%;
    gap: 30px;
    background: $bg-primary;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 30px;

    @include small-desktop {
        padding: 20px;
        gap: 20px;
    }

    @include tablet {
        padding: 16px;
        gap: 20px;
    }
}