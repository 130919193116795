@import "../../../scss/global.scss";

.maximum {
    padding: 64px 0;
    background-color: $bg-primary2;

    @include tablet {
        padding: 40px 0;
    }

    &__products {
        padding: 40px 0 64px;

        @include tablet {
            padding: 12px 0 40px;
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__title {
        color: $text-secondary;
        @include t_48;
        text-align: center;

        span {
            color: $text-actions;
        }
    }

    &__subtitle {
        margin-top: 10px;
        color: $text-secondary;
        @include t_18;
        text-align: center;
    }

    &__list {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        &_href{
            color: #ed7e2e;
            cursor: pointer;
            padding-top: 2px;
        }

        li {
            display: flex;
            gap: 8px;
            color: $text-secondary;
            padding-bottom: 5px;

            @include t_16;
            &:before {
                content: "";
                width: 9px;
                height: 13px;
                border: 2px solid #ed7e2e;
                border-left: none;
                border-top: none;
                transform: rotate(45deg);
                padding-left: 2px;
            }
        }
    }

    &__top {
        display: flex;
        align-items: center;
        gap: 12px;
        margin: 48px auto 0;

        @include tablet {
            margin-top: 24px;
        }
    }

    &__margin {
        margin-bottom: 16px;
    }

    &__receptTitle {
        color: $text-secondary;
        @include t_32;
    }

    &__recept {
        display: flex;
        align-items: stretch;
        justify-content: center;
        margin: 0 auto;
        margin-top: 16px;
        gap: 16px;
        width: 100%;

        @include tablet {
            flex-wrap: wrap;
        }
    }

    &__item {
        max-width: 440px;
        background-color: $bg-additional;
        border-radius: 24px;
        padding: 32px;
        width: 100%;

        @include small-desktop {
            max-width: 480px;
        }

        @include tablet {
            padding: 16px;
        }

        &_title {
            color: $text-secondary;
            text-align: center;
            @include t_24;
            margin-top: 16px;
        }

        &_cost {
            color: $text-secondary;
            text-align: center;
            @include t_32;
            margin-top: 8px;
            font-family: "Golos Text", sans-serif !important;
        }

        &_line {
            width: 100%;
            margin-top: 16px;
            height: 1px;
            background-color: $bg-additional3;
        }

        &_top {
            display: flex;
            align-items: center;
            justify-content: center;
            background: $bg-additional2;
            border-radius: 16px;

            img {
                max-height: 105px;
            }
        }

        &_description {
            color: $text-secondary2;
            @include t_14;
            text-align: center;
        }
    }
}
