@import '../../scss/global.scss';

.products {
    background-color: $bg-secondary;

    &__top {
        background-color: $bg-primary2;
        padding-top: calc($content-padding-top-desktop + 16px);

        @include small-desktop {
            padding-top: calc($content-padding-top + 16px);
        }
    }
}