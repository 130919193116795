@import '../../../../scss/global.scss';

.ticketTime {
    &__time {
        @include t_32;
        font-weight: 400;
        color: $text-primary;
        display: flex;
        position: relative;
        @include tablet {
            font-weight: 400;
        }

        &__clock{
            position: absolute;
            left: 7px;
            top: -10px;
            width: fit-content;
        }

    }

    &__date {
        margin-top: 4px;
        color: $text-primary;
        @include t_14;
    }

    &__airport {
        color: $text-primary;
        @include t_14;
    }
}
