$color_1: #737b84;

/* lang */
.lang {
    position: relative;
    z-index: 10;
    text-align: center;
    perspective: 700px;
}
.lang_fixed {
    position: fixed;
    right: 20px;
    top: 20px;
}
.lang__link {
    cursor: pointer;
    transition: 0.3s all;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    box-sizing: border-box;
    text-decoration: none;
    border-radius: 2px;
    padding: 10px 15px;
    svg {
        border-radius: 50%;
        width: 25px;
        height: 25px;
        flex-shrink: 0;
        font-size: 10px;
        display: block;
        transition: 0.3s all;
    }
    &:hover {
        background-color: rgba(0, 108, 228, 0.06);
    }
}
.lang__link_sub {
    height: auto;
    position: relative;
    margin-bottom: 2px;
    svg {
        margin-right: 5px;
    }
    &.active {
        background-color: rgba(0, 108, 228, 0.06);
    }
}
.lang__name {
    color: $color_1;
    font-size: 12px;
    line-height: 12px;
    flex-shrink: 0;
    text-transform: uppercase;
}
.lang__list {
    display: flex;
    align-items: center;
    width: 100%;
    transition: 0.3s all;
    line-height: 13px;
    padding: 4px;
}
.lang__link_select {
    align-items: flex-start;
    text-align: center;
    font-size: 0;
}
