@import '../../../../scss/global.scss';

.ticketTransfer {
    &__time {
        color: $text-primary;
        @include t_16;
    }

    &__transfer {
        margin-top: 4px;
        color: $text-secondary2;
        @include t_12;
    }
}