@import "../../../../scss/global.scss";

.card {
    background: $bg-primary;
    border: 2px solid $text-secondary3;
    border-radius: 16px;
    justify-content: space-between;
    width: 48%;

    @include tablet {
        width: 100%;
    }

    &__active {
        border-color: $bg;
    }

    &__header {
        padding: 16px 24px;
        background: $bg-secondary;
        border-radius: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
            height: 56px;
        }
    }

    &__main {
        padding: 16px 24px 24px;

        @include tablet {
            padding: 16px;
        }

        &_logo {
            color: $text-primary;
            @include t_16;
            font-weight: 700 !important;
        }

        &_text {
            color: $text-primary;
            @include t_20;
            font-weight: 700 !important;

            span {
                color: $text-secondary2;
                @include t_14;
            }
        }
    }

    &__list {
        ul {
            margin-top: 16px;
            display: flex;
            flex-direction: column;
            gap: 4px;
            margin-bottom: 10px;
            li {
                display: flex;
                gap: 8px;
                color: $text-primary;
                @include t_14;
                &:before {
                    content: "";
                    width: 9px;
                    height: 13px;
                    border: 2px solid #ed7e2e;
                    border-left: none;
                    border-top: none;
                    transform: rotate(45deg);
                    padding-left: 2px;
                }
            }
        }

        &_btn {
            color: $text-actions;
            margin-top: 4px;
            @include t_14;
            background: none;
        }
    }
}
