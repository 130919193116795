@import "../../../scss/variables.scss";

.custom_checkbox{
  :global{
    .ant-checkbox-inner{
      width: 24px;
      height: 24px;
      border-radius: 8px;
      border-color: $bg-secondary2 !important;
    }

    .ant-checkbox{
      transform: none;
    }

    .ant-checkbox + span{
      display: flex;
      align-items: center;

      img{
        border-radius: 12px;
        margin-right: 4px;
      }
    }

    .ant-checkbox + span > div{
      margin-right: 8px;
    }

    .ant-checkbox-checked .ant-checkbox-inner{
      background-color: $bg-primary2 !important;
      border: none;

      &:after{
        inset-inline-start: 30%;
      }
    }

    .ant-checkbox-checked:after{
      border: none;
    }
  }
  &:hover span{
    color: $text-actions !important;
  }
}

.error_text {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: $text-error;
  margin-top: 2px;
}