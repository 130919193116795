@import "../../../scss/global.scss";

.calendar_wrapper{
  width: 350px;
  background: $bg-primary;
  border-radius: 12px;
  padding: 24px;
  user-select:none;

  @include small-desktop{
      padding: 0;
      width: 48%;
  }

 @include mobile{
     width: 100%;
 }

  .header{
    padding: 25px 30px 10px;
    position: relative;

    .current_date{
      text-align: center;
      font-weight: 700;
      font-size: 16px;
    }

    .left_icon{
      position: absolute;
    }

    .right_icon{
      position: absolute;
      right: 24px;
      bottom: 8px;
    }
  }

  .calendar{
    .weeks li {
      font-weight: 500;
      color: $text-secondary2;

      &:hover{
        border: none;
      }
    }
    ul {
      display: flex;
      list-style: none;
      flex-wrap: wrap;
      text-align: center;

      li{
        width: 42px;
        height: 42px;
        cursor: pointer;
        line-height: 42px;

        &:hover{
            border: 1px solid $bg;
            border-radius: 8px;
        }

        @include small-desktop{
          width: calc(100% / 7);
        }
      }
    }

    .inactive_day{
      color: $text-secondary2;
        &:hover{
            border: none;
        }
    }

    .selected_day:not(.inactive_day){
      color: $bg-primary;
      background: $bg-primary2;
      border-radius: 8px;
    }

    .selected_days_range{
      background: $bg-additional4;
    }

  }
}
