@import '../../scss//global.scss';


.partner {

    &__spinner {
        margin-top: 16px;
    }

    &__modalTitle {
        margin: 8px 0;
    }

    &__mobile {
        display: none;
    }

    &__container {
        display: flex;
        flex-direction: column;
        max-width: 1440px;
        padding: 0 44px;
        margin: 0 auto;

        @include small-desktop {
            padding: 0 24px;
        }
        @include tablet{
            padding: 0 16px;
        }

    }

    &__top {
        padding: 110px 0 170px;
        background-image: url(../../assets/img/bg-partner.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        padding-top: calc($content-padding-top-desktop + 16px);
        border-radius: 0px 0px 24px 24px;
        @include small-desktop {
            padding-top: calc($content-padding-top + 16px);
        }
    }

    &__video {
        background-color: $bg-primary2;
    }

    &__header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 104px;

        @include tablet {
            margin-top: 32px;
        }

        &_btn {
            margin-top: 32px;
        }
    }

    &__title {
        color: $text-secondary;
        @include t_72;
    }

    &__subtitle {
        margin-top: 16px;
        color: $text-secondary;
        @include t_18;
    }

    &__member {
        background: #ED7E2E;
        border-radius: 24px;
        padding: 40px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        @include tablet {
            padding: 20px;
        }

        &_title {
            color: $text-secondary;
            @include t_48;

            @include small-desktop {
                width: 85%;
            }
        }


        &_btn {
            margin-top: 200px;

            @include tablet {
                width: 100%;
                margin-top: 0;
                margin-bottom: 170px;
            }

            @include mobile {
                margin-bottom: 130px;
            }
        }

        &_group {
            display: flex;
            justify-content: space-between;
            gap: 16px;
            position: relative;

            @include tablet {
                flex-direction: column;
            }

            img {
                position: absolute;
                max-width: 336px;
                bottom: -35px;
                right: -35px;

                @include tablet {
                    max-width: 200px;
                    margin-left: auto;
                    bottom: -20px;
                    right: -20px;
                }

                @include mobile {
                    max-width: 150px;
                }
            }
        }
    }
}
