@import '../../../../scss/global.scss';

.transfer {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &__direction {
        @include t_16;
        font-weight: 700 !important;
        color: $text-primary;
        
    }

    &__time {
        @include t_14;
    }
}
