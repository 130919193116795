@import '../../scss/global.scss';

.ui_kit_item{
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 0 20px;
}

.partner {
    &__title {
        @include t_32;
        margin-top: 8px;
        margin-bottom: 8px;
        text-align: center;    
    }

    &__img {
        width: 64px;
        aspect-ratio: 1/1
    }

    &__text {
        @include t_16;
        text-align: center;

       a {
            span {
                color: $text-actions;
            }
       }
    }

    &__btn {
        height: auto;
        padding: 20px;
    }
}