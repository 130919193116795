@import 'scss/global.scss';

.modal{
    max-width: 350px;
    width: 100%;
}

.content{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 15px;
}

.img{
    max-width: 65px;
    width: 100%;
}

.title{
    margin-bottom: 8px;
    text-align: center;
}

.subtitle{
    margin-bottom: 18px;
    text-align: center;
}

.desc1, .desc2{
    padding-left: 24px;
    object-fit: contain;
}

.desc1{
    background: url('../../../../assets/icons/orangeCheckmark.svg') no-repeat;
    margin-bottom: 20px;
    background-position: 0 5px;
}

.desc2{
    background: url('../../../../assets/icons/arrowRight.svg') no-repeat;
    margin-top: 20px;
    background-position: 0 5px;
}

.line{
    background: $btn-addetional-hover;
    height: 1px;
    width: 100%;
}