@import '../../../scss/global.scss';

.custom_breadcrumb {
    ol {
        line-height: normal;
        align-items: normal;
    }

    :global {

        a,
        li:last-child {
            color: $text-primary;
            @include t_14;
            line-height: unset;

            &:hover {
                background: transparent;

            }
        }

    }


    :global {

        li:not(:last-child) {
            a {
                color: $text-secondary2 !important;
                &:hover {
                    color: $text-actions !important;
                }
            }

        }
    }

    svg {
        transform: rotate(180deg);

        path{
            stroke: $text-secondary2;
        }
    }

    &_white {

        .custom_breadcrumb {
            ol {
                align-items: center!important;
            }
        }
        a,
        li:last-child {
            &:hover {
                color: $text-secondary !important;
                background: transparent;
            }
        }

        :global {

            li:last-child {
                a {
                    color: $text-secondary !important;
                }

            }
        }

        svg{
            transform: none;
        }
    }
}
