@import "../../../scss/global.scss";

.balance_page {
    flex: 1;

    &_empty {
        font-size: 20px;
        font-weight: 700;
        margin-top: 16px;
        margin-bottom: 8px;
        text-align: center;

        &_text {
            @include t_14;
            margin-bottom: 16px;
            text-align: center;
        }
    }

    &_name {
        @include t_14;
    }

    &_link {
        @include t_12;
        color: $text-secondary2;
    }

    @include small-desktop {
        padding: 0;
    }

    &_show_more {
        width: 100%;
        margin-top: 16px;
    }

    &_title {
        @include t_18;
        font-weight: 700;
        margin: 16px 0;

        @include small-desktop {
            font-weight: 700;
        }
    }

    &_sort_button {
        display: none;
        width: 100%;
        margin-bottom: 32px;
        margin-top: 16px;

        @include tablet {
            display: block;
        }
    }

    &_table {
        @include tablet {
            display: none;
        }
    }

    &_sorting {
        display: none;

        &_visible {
            position: absolute;
            top: 96px;
            bottom: 0;
            width: 100%;
            background: #fff;
            padding-right: 24px;

            @include tablet {
                display: block;
            }
        }
    }

    &_list {
        display: none;

        @include tablet {
            display: block;
        }
    }

    .sum {
        color: $text-done;
    }
}

.balance {
    background: $bg-primary;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    padding: 24px;
    margin-top: 16px;

    @include tablet {
        padding: 0 16px;
    }

    &_info {
        display: flex;
        padding-top: 16px;

        @include tablet {
            flex-wrap: wrap;
        }

        &_item {
            width: 48%;

            @include tablet {
                margin-bottom: 16px;
            }

            &_title {
                @include t_12;
                margin-bottom: 4px;
                color: $text-secondary2;
            }

            &_value {
                @include t_14;
            }
        }
        &_card_number {
            color: $text-secondary2;
            @include t_12;
        }
    }
}

.current_balance {
    color: $text-actions;
}
