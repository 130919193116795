@import "scss/global.scss";

.wrapper {
    display: flex;
    gap: 4px;
    width: 100%;
}

.icon {
    svg {
        width: 20px;
        height: 20px;
    }
}

.small{
    svg {
        width: 15px;
        height: 15px;
    }
}

.content{
    width: 100%;
}

.name, .note {
    @include t_12;
    color: $text-secondary2;
    margin-bottom: 2px;
}

.info {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.link {
    @include t_14;
    color: $text-primary;
    color: $bg;
    text-decoration: underline;
}

.title {
    @include t_14;
    color: $text-primary;
}

.price {
    @include t_14;
    color: $text-primary;
    margin-left: 10px;
    text-wrap: nowrap;
}