@import 'scss/global.scss';

// Стили для заголовков
.h1 {
  font-size: 4.5rem; // 72px
  font-weight: bold;

  @include tablet {
    font-size: 2.125rem; // 34px
  }
}

.h2 {
  font-size: 3rem; // 48px
  font-weight: bold;

  @include tablet {
    font-size: 2rem; // 32px
  }
}

.h3 {
  font-size: 2rem; // 32px
  font-weight: bold;

  @include tablet {
    font-size: 1.5rem; // 24px
  }
}

.h4 {
  font-size: 1.5rem; // 24px
  font-weight: bold;

  @include tablet {
    font-size: 1.25rem; // 20px
  }
}

.h5 {
  font-size: 1.25rem; // 20px
  font-weight: bold;

  @include tablet {
    font-size: 1.125rem; // 18px
  }
}

.p {
  font-size: 1rem; // 16px

  @include tablet {
    font-size: 0.875rem; // 14px
  }
}
