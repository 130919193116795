@import "../../../scss/global.scss";

.VisaPaymentSuccess {
    background-color: $bg-secondary;

    padding-top: calc($content-padding-top-desktop + 32px);

    @include small-desktop {
        padding-top: calc($content-padding-top + 16px);
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }


    &__tickets {
        filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1));
    }

    &__title {
    }
}

.sumItems {
    @include t_14;
    color: $text-secondary2;
}
