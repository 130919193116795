@import '../../../scss/global.scss';

.radio {
    display: flex;
    gap: 12px;
    margin-bottom: 18px;
    cursor: pointer;
    border-radius: 50%;
    background: $text-secondary;
    border: 1px solid $text-secondary3;
    cursor: pointer;

    &__input {
        appearance: none;
        width: 23px;
        height: 23px;
        border-radius: 50%;
        position: relative;
        cursor: pointer;

        &:checked {
            background: $bg;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;

            &::after {
                content: url('../../../assets//icons/whiteCheckMark.svg');
                position: absolute;
            }
        }
    }
}