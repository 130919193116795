@import '../../scss/global.scss';

.faq {
    position: relative;
    padding-top: calc($content-padding-top-desktop + 16px);

    @include small-desktop {
        padding-top: calc($content-padding-top + 16px);
    }


    &__divide {
        display: none;

        &_block {
            display: block;
        }
    }

    &__block {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-left: auto;


        @include tablet {
            gap: 20px;
        }

        &_item {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }

        &_title {
            color: $text-primary;
            @include t_32;
        }
    }

    &__header {
        position: relative;
        width: 100%;
        padding: 40px;
        background: $bg-primary2;
        border-radius: 24px;
        margin-top: 32px;

        &_title {
            color: $text-secondary;
            @include t_48
        }

        img {
            position: absolute;
            right: 24px;
            bottom: 8px;
        }

        @include tablet {
            margin-top: 12px;
            padding: 24px 16px 80px;

            img {
                right: 0;
                max-width: 72px;
                bottom: 0;
            }

        }
    }

    &__main {
        display: grid;
        grid-template-columns: 1fr 3fr;
        gap: 16px;
        margin-top: 24px;
        height: fit-content;

        @include small-desktop {
            grid-template-columns: 1fr;
        }

        @include tablet {
            margin-top: 16px;
        }
    }

    &__aside {
        position: sticky;
        top: 155px;
        height: fit-content;

        @include small-desktop {
            display: none !important;
        }

        &_list {
            display: flex;
            flex-direction: column;
        }

        &_active {
            background: $bg-primary2;
            color: $text-secondary !important;
        }

        &_item {
            padding: 12px;
            cursor: pointer;
            border-radius: 16px;
            user-select: none;
            text-decoration: none;
            color: $text-primary;
            @include t_14;
            width: fit-content;

            &:hover {
                background-color: $btn-addetional-hover;
                color: $bg-primary2 !important;
            }
        }
    }

    &_link{
        color: $text-actions
    }
}
