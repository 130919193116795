@import '../../../scss/global.scss';

.hotel {
    background: $bg-primary;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 32px;
    display: flex;
    flex-direction: column;

    @include tablet {
        padding: 16px;
    }

    &__title {
        color: $text-primary;
        @include t_24;
        font-weight: 700 !important;
    }

    &__info {
        margin-top: 16px;
        border-radius: 16px;
        background-color: $bg-secondary;
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        position: relative;

        @include tablet {
            margin-top: 12px;
        }

        &_title {
            color: $text-primary;
            @include t_16;
            font-weight: 700 !important;
        }

        &_text {
            color: $text-primary;
            @include t_14;
        }
    }

    img {
        position: absolute;
        bottom: 0;
        right: 25px;

        @include tablet {
            right: 0;
            max-width: 64px;
        }
    }
}