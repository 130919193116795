@import '../../../../scss/global.scss';

.drawer {
    display: flex;
    flex-direction: column;
    background: $bg-primary;
    height: 100vh;
    text-align: left;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    transition: transform 0.4s ease-in-out, opacity 0.2s ease-in-out;
    z-index: -1;
    width: 100%;
    gap: 34px;
    padding: 77px 24px;

    @include tablet {
        padding: 77px 16px;
    }
}