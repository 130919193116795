@import '../../../scss/global.scss';

.qa {
    padding: 64px 0;
    background: $bg-secondary;

    @include tablet {
        padding: 40px 0;
    }


    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__title {
        color: $text-primary;
        @include t_48;
    }

    &__btn {
        columns: $text-actions;
        @include t_16;
        padding: 18px 32px;
        background: $bg-secondary;
        border: 1px solid $btn-primary;
        border-radius: 12px;
        color: $btn-primary;
        margin-top: 14px;
        font-weight: 700!important;

        @include tablet {
            display: none;
        }
    }

    &__block {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 32px;
        gap: 16px;

        @include small-desktop {
            margin-top: 24px;
        }

        @include tablet {
            display: block;
        }
    }

    &__btnMobile {
        display: none;
        width: 100%;

        @include tablet {
            display: block;
        }
    }

    &__card {
        min-width: 440px;
        background: $bg-primary2;
        border-radius: 24px;
        padding: 32px;

        @include tablet {
            padding: 16px;
            margin-top: 16px;
        }

        @include small-desktop {
            min-width: 360px;
        }

        @include tablet {
            min-width: auto;
            width: auto;

        }

        &_title {
            color: $text-secondary;
            @include t_24;
            margin-top: 8px;
        }

        &_text {
            color: $text-secondary3;
            margin-top: 8px;
            @include t_16;

        }

        &_btn {
            padding: 20px;
            width: 100%;
            background: $btn-primary;
            border-radius: 12px;
            margin-top: 24px;
            border: 0;
            color: $text-secondary;
            @include t_16;
        }
    }
}