@import '../../../scss/global.scss';

.custom_slider{
  :global{
    .ant-slider-rail{
      background-color: $bg-secondary2;
    }

    .ant-slider-track, .ant-slider:hover .ant-slider-track{
      background-color: $bg !important;
    }
    .ant-slider-handle::after, .ant-slider-handle:focus::after, .ant-slider-handle:hover::after{
      box-shadow: 0 0 0 2px $bg !important;
      width: 18px;
      height: 18px;
      inset-block-start: -5px;
    }
  }
}
