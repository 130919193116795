@import "../../../scss/global.scss";

.custom_tag {
    padding: 0 16px;
    height: 41px;
    border-radius: 24px;
    @include t_14;
    line-height: 38px;

    @include small-desktop{
        line-height: 38px;
    }

    &_active {
        background: $bg-primary;
        border: 1px solid $bg-secondary2;

        &:hover {
            border-color: $btn-primary;
            cursor: pointer;
        }
    }

    &_inactive {
        background: $bg-primary2;
        color: $text-secondary;

        &:hover {
            background: $btn-secondary-hover;
            cursor: pointer;
        }
    }
}