@import '../../../../scss/global.scss';

.status {
    background: $bg-primary;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include tablet {
        padding: 16px;
    }

    &__issued {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &_text {
            @include t_14;
            color: $text-secondary2;

        }

        &_doc {
            display: flex;
            align-items: center;
            padding: 8px 12px;
            gap: 8px;
            background: $bg-secondary;
            border-radius: 8px;
            width: fit-content;

            @include tablet {
                width: auto;
            }
        }
    }

    &__btn {
        display: flex;
        gap: 9px;
        align-items: center;
        cursor: pointer;
        width: fit-content;

        button {
            color: $text-actions;
            @include t_14;
            background: none;
        }

        svg {
            margin-top: 4px;
        }
    }
}

.about {
    padding: 0;


    &__title {
        color: $text-primary;
        @include t_24;
        font-weight: 700 !important;
    }

    &__content {
        display: grid;
        grid-template-columns: repeat(4, 160px);
        justify-content: space-between;
        max-width: 90%;

        @include tablet {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 12px;
            max-width: 100%;

        }

        &_item {
            display: flex;
            flex-direction: column;
            gap: 8px;
            color: $text-primary;
        }

        &_text {
            color: $text-primary;
            @include t_14;
            font-weight: 700 !important;
        }

        &_info {
            margin-top: -6px;
        }

        &_order_no {
            font-weight: bold;
            padding-top: 15px;
            p {
                font-size: 14px;
                line-height: 17px;
                font-weight: 700 !important;
                a {
                    color: #ed7e2e;
                }
            }
        }        
    }
}

.documents {
    padding: 0;


    &__title {
        color: $text-primary;
        @include t_24;
        font-weight: 700 !important;
    }

    &__subtitle {
        color: $text-primary;
        @include t_14;

    }

    &__buttons {
        display: flex;
        gap: 24px;
        margin-top: 8px;

        @include tablet {
            flex-direction: column;
            gap: 8px;
        }

    }

    &__btn {
        display: flex;
        height: auto;
        padding: 6px 18px;


        div {
            margin-top: 3px;
        }

        @include small-desktop {
            padding: 4px 18px;
        }

        @include tablet {
            padding: 2px 18px;

        }

    }
}

.hotel {
    background: $bg-primary;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 32px;
    display: flex;
    flex-direction: column;

    @include tablet {
        padding: 16px;
    }

    &__title {
        color: $text-primary;
        @include t_24;
        font-weight: 700 !important;
    }

    &__info {
        border-radius: 16px;
        background-color: $bg-secondary;
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        position: relative;

        &_title {
            color: $text-primary;
            @include t_16;
            font-weight: 700 !important;
        }

        &_text {
            color: $text-secondary2;
            @include t_14;

        }

        img {
            position: absolute;
            bottom: 0;
            right: 25px;

            @include tablet {
                right: 0;
                max-width: 64px;
            }
        }
    }
}

.payment {
    &__btn {      
        @include small-desktop {
            width: 100%;
        }

        @include tablet {
            width: 100%;
        }

    }
}