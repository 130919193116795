@import 'scss/global.scss';

.payment {
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1));
    border-radius: 16px;
    height: fit-content;
    background: $bg-primary;
    padding: 24px;
    display: flex;
    flex-direction: column;
    width: 37%;

    @include small-desktop {
        overflow-y: initial;
        max-height: 100%;
    }

    @include tablet {
        padding: 16px;
        width: 100%;
    }

    &__title {
        @include t_20;
        color: $text-primary;
        font-weight: 700 !important;
        margin-bottom: 16px;
    }

    &__items {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin: 16px 0;
    }
    &__title5 {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #8a8a8a;
        margin-top: 4px;
        margin-bottom: 30px;
    }
}

.warning {
    display: flex;
    color: $text-error;
    gap: 8px;
}


.divider {
    width: 100%;
    height: 1px;
    background: $btn-addetional-hover;
}


.info {
    display: flex;
    flex-direction: column;
    margin-top: 9px;

    &_text {
        @include t_12;
        color: $text-secondary2;
    }

    &_link {
        @include t_12;
        color: $bg;
    }
}
