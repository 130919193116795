@import '../../../scss/global.scss';

.status {
    padding: 6px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700 !important;
    @include t_14;
    color: $text-secondary;
    border-radius: 24px;
    width: fit-content;

    &__confirmed {
        background-color: $btn-secondary2;
    }

    &__cancelled {
        background-color: $text-secondary3;
    }

    &__indeed {
        background-color: $text-done;
    }

    &__notConfirmed {
        background-color: $text-error;
    }
}