@import '../../../scss/global.scss';

.comment {
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 16px;
    background: $bg-primary;
    padding: 32px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.10);

    @include tablet {
        padding: 16px
    }

    &__title {
        @include t_24;
        color: $text-primary;
    }

    &__textarea {
        padding: 22px 16px;
        background: $bg-primary;
        border: 1px solid $text-secondary3;
        border-radius: 8px;
        resize: none;
        height: 110px;
        color: $text-primary;
        @include t_16;
    }
}
