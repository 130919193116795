@import '../../../scss/global.scss';

.video {
    padding: 64px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include tablet {
        padding: 32px 0;
    }

    &__title {
        @include t_48;
        color: $text-secondary;
        text-align: center;

        span {
            color: $text-actions;
        }
    }

    &__subtitle {
        @include t_18;
        color: $text-secondary;
        text-align: center;
        margin-top: 16px;

    }

    &__player {
        margin-top: 48px;
        max-width: 980px;
        max-height: 670px;

        @include small-desktop {
            max-width: 388px;
        }

        @include tablet {
            margin-top: 24px;
        }
    }
}