@import 'scss/global.scss';

.forms{
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: flex-start;
}

.form {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;

    &>* {
        width: calc(50% - 16px / 2);

        @include tablet{
            width: 100%;
        }
    }
}

.note {
    color: $text-secondary2;
    margin-top: 8px;
}

.text{
    display: flex;
    align-items: center;
    gap: 12px;
}

.title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 30px;
    margin-bottom: 20px;
}

.trash{
    cursor: pointer;
}