@import '../../../../scss/global.scss';

.form {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__radio {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 100%;
        height: max-content;
        input {
            display: none;
        }

        &_active {
            background: $bg-primary;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            color: $text-primary !important;
            font-weight: 700 !important;

        }


        &_item {
            cursor: pointer;
            color: $text-secondary2;
            @include t_14;
            width: inherit;
            display: flex;
            justify-content: center;
            align-items: center;
            user-select: none;
            padding: 8px;


        }

        &_title {
            color: $text-secondary2;
            @include t_14;
        }

        &_labels {
            display: flex;
            width: 100%;
            background: $btn-addetional;
            border-radius: 12px;
            align-items: center;
            justify-content: space-around;
            padding: 4px;
            max-height: 62px;
            height: 100%;
        }
    }

    &__field {
        display: flex;
        flex-direction: column;
        gap: 16px;

        &_half{
            width: calc(50% - 8px);
            @include tablet {
                width: 100%;
            }
        }

        input {
            max-height: 62px;
        }

        &_doc {
            display: flex;
            gap: 8px;
            align-items: center;
        }

        &_docTitle {
            color: $text-primary;
            @include t_16;
            font-weight: 700!important;
        }


        &_textContainer {
            width: 100%;
        }

        &_header {
            display: flex;
            gap: 8px;
            align-items: center;
        }

        &_title {
            color: $text-primary;
            @include t_20;

            span {
                color: $text-actions;
            }
        }

        &_vertical {
            display: flex;
            flex-direction: column;
            gap: 8px;
            width: 100%;
        }

        &_info {
            color: $text-secondary2;
            @include t_14;
        }

        &_checkbox {
            display: flex;
            gap: 4px;
            align-items: center;
            color: $text-primary;
            @include t_14;
            user-select: none;
            cursor: pointer;
            width: fit-content;
        }

        &_select {
            width: calc(50% - 8px);
            @include tablet {
                width: 100%;

             }
        }


        &_group {
            display: flex;
            gap: 16px;

            @include tablet {
               flex-direction: column;

            }
        }


        &_text {
            min-width: 50%;
        }

        &_date {
            padding: 22px 16px;
            min-width: calc(50% - 8px);
            background: $bg-primary;
            border: 1px solid $text-secondary3;
            border-radius: 8px;
            width: 100%;
        }

        &_dateDisabled {
            background: $bg-secondary;
        }

        &_error {
            border-color: $text-error;
        }

        &_errorRadio {
            border: 1px solid $text-error;
        }
    }
}

.error_text {
    @include t_12;
    color: $text-error;
}

.profilePassangersWrapper {
    display: grid;
}
