@import '../../../scss/global.scss';


.datepicker {
    position: relative;



    &_input {
        border-radius: 8px;
        border: 1px solid $bg-secondary2;
        height: 62px;
        padding: 21px 16px 8px;
        @include t_16;
        color: $text-secondary;
        caret-color: $text-primary;
        width: 100%;
        &:disabled {
            background-color: #f8f8fa;
            border-color: $text-secondary3;
            color: $text-secondary3;
            cursor: not-allowed;
        }
        &_error {
            border-color: $text-error;
        }

        &:hover,
        &:focus {
            border-color: $text-secondary2 !important;
        }
    }

    &_field_name {
        position: absolute;
        left: 16px;
        top: 12px;
        color: $text-secondary2;
    }

    &_values {
        position: absolute;
        left: 16px;
        top: 30px;

        &_placeholder {
            z-index: 0;
            @include t_16;
            color: $text-secondary3;
        }

        &_value {
            color: $text-primary;
            @include t_16;
            z-index: 1;
        }
    }

    .error_text {
        @include t_12;
        color: $text-error;
        margin-top: 2px;
    }
}
.adaptive {
    max-width: calc(100% - 16px * 2); // Ширина адаптируется
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}