@import 'scss/global.scss';


.wrapper{
    border-radius: 16px;
    border-width: 2px;
    border-style: solid;
    border-color: $text-secondary3;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.header {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    background: $bg-secondary;
    align-items: center;
}

.body {
    padding: 16px 24px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    background: $bg-primary;
}

.src {
    width: 60px;
    height: 60px;
    object-fit: contain;
}

.wrapper {
    display: flex;
    gap: 6px;
    width: 100%;
}

.icon {
    svg {
        width: 16px;
        height: 16px;
    }
}

.content {
    width: 100%;
}

.time {
    @include t_14;
    color: $text-primary;
}

p.title {
    @include t_16;
    font-weight: 700;
}

.price {
    @include t_20;
    font-weight: 700;

    span {
        @include t_14;
        color: $text-secondary2;
    }
}

.timeWrapper{
    display: flex;
    gap: 8px;
    align-items: center;
}








@import 'scss/global.scss';


.wrapper{
    border-radius: 16px;
    border-width: 2px;
    border-style: solid;
    border-color: $text-secondary3;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.header {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    background: $bg-secondary;
    align-items: center;
}

.body {
    padding: 16px 24px;
    gap: 16px;
    display: flex;
    flex-direction: column;
}

.checked {
    border-color: $bg;
}

.src {
    width: 60px;
    height: 60px;
    object-fit: contain;
}

.wrapper {
    display: flex;
    gap: 6px;
    width: 100%;
}

.icon {
    svg {
        width: 16px;
        height: 16px;
    }
}

.content {
    width: 100%;
}

.time {
    @include t_14;
    color: $text-primary;
}

.title {
    @include t_16;
}

.subtitle {
    @include t_20;

    span {
        @include t_14;
        color: $text-secondary2;
    }
}