@import 'scss/global.scss';

.page {
    padding-top: calc($content-padding-top-desktop + 32px);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @include small-desktop {
        padding-top: calc($content-padding-top + 32px);
    }
}

.container{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.wrapper {
    display: flex;
    padding: 10px 0 32px;
    gap: 15px;

    @include tablet {
        flex-direction: column;
    }
}

.info{
    @include tablet {
        flex-direction: column-reverse;   
    }
}

.children{
    width: 100%;
}

.progress{
    margin-bottom: 42px;
}