@import '../../../scss/global.scss';

.form {
    &__radio {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 100%;

        input {
            display: none;
        }

        &_active {
            background: $bg-primary;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            color: $text-primary !important;
            font-weight: 700 !important;

        }

        &_errorRadio {
            border: 1px solid $text-error;
        }

        &_item {
            color: $text-secondary2;
            @include t_14;
            width: inherit;
            display: flex;
            justify-content: center;
            height: 100%;
            align-items: center;
            user-select: none;
        }

        &_title {
            color: $text-secondary2;
            @include t_14;
        }

        &_labels {
            display: flex;
            width: 100%;
            background: $btn-addetional;
            border-radius: 12px;
            height: 100%;
            align-items: center;
            justify-content: space-around;
            padding: 4px;
            @include tablet{
                height: 62px;
            }
        }
    }
}

.error_text {
    @include t_12;
    color: $text-error;
}
