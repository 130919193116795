@import '../../../scss/global.scss';

.player {
    &__icon {
        width: 96px;
        height: 96px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;

        @include tablet {
            width: 56px;
            height: 56px;
        }
    }
}
