@import "../../../scss/global.scss";

.custom_form {
    .login_form_forgot {
        color: $text-actions-hover;
    }

    .register_link {
        text-align: center;
        margin-top: 16px;

        a {
            color: $text-actions-hover;
        }
    }

    :global {
        .ant-form-item {
            margin-bottom: 16px;
        }

        .ant-divider {
            margin: 0;
        }
    }

    .help_text {
        @include t_14;
        color: $text-secondary2;
        margin-bottom: 8px;
    }

    .buttons {
        :global {
            .ant-form-item-row {
                width: 100%;
            }

            .ant-form-item-control-input-content {
                display: flex;
                justify-content: space-between;

                @include tablet {
                    display: block;
                }

                button {
                    width: 48%;
                    text-align: center;

                    @include tablet {
                        width: 100%;
                        margin-bottom: 16px;
                    }
                }
            }
        }
    }

    .google_icon {
        height: 35px;
    }
}

.warning {
    display: flex;
    margin-top: 9px;
    color: $text-error;

    svg {
        margin-right: 5px;
        margin-top: 3px;
    }
}

.dropdown_label {
    @include t_12;
    color: $text-secondary2;
}

.partner {
    &__subtitle {
        margin-top: 20px;
        @include t_16;
        font-weight: 700 !important;
        margin-bottom: 8px;
    }
}
.carrency {
    &__subtitle {
        margin-top: 20px;
        @include t_16;
        font-weight: 700 !important;
        margin-bottom: 8px;
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: 0px -10px;
    }
}

.form {
    &__card {
        &_title {
            margin-bottom: -8px;
            @include t_14;
            font-weight: 700 !important;
        }
    }

    &__req {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        &_tree {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 16px;

            @include tablet {
                grid-template-columns: 1fr;
            }
        }

        &_one {
            display: grid;
            gap: 16px;
        }
    }

    &__text {
        @include t_14;
        color: $text-secondary2;
        margin-bottom: 8px;
        margin-top: 8px;
    }

    &__errorText {
        margin-top: 8px;
        color: $text-error;
        @include t_12;
    }

    &__btns {
        display: flex;

        button {
            width: 100%;
            margin-top: 20px;
        }
    }

    :global {
        .ant-form-item {
            margin-bottom: 0;
        }

        .ant-modal-title {
            margin-bottom: 20px;
        }

        .ant-divider {
            margin: 0;
        }
    }
}

.req_dropdown {
    margin-bottom: 16px;
}
